angular
    .module('annexaApp')
        .controller('QueryController',['$scope', '$rootScope', 'AnnexaFormlyFactory', 'AnnexaModalFactory', 'CommonAdminModals', 'HeaderService', '$state', 'TableFilter', 'DTColumnBuilder', '$filter', 'RestService', 'Language', 'HelperService', 'ErrorFactory','AdminFactory', 'DialogsFactory', 'GlobalDataFactory', 'QuerysModals', 'QueryFactory',
            function($scope, $rootScope, AnnexaFormlyFactory, AnnexaModalFactory, CommonAdminModals, HeaderService, $state, TableFilter, DTColumnBuilder, $filter, RestService, Language, HelperService, ErrorFactory, AdminFactory, DialogsFactory, GlobalDataFactory, QuerysModals, QueryFactory) {
                $scope.showLoadingdiv = false;
                $scope.languageColumn = Language.getActiveColumn();
                $scope.tfilter = new TableFilter();
                $scope.tfilter.addElement($scope.languageColumn, 'text', 1, 'global.literals.name');
                $scope.modalQuery;
                var loggedUser = $rootScope.LoggedUser;

                $scope.dtDefQuery = new TableData('queries', 'Query', './api/query/byfilter').setSort([[1, 'asc']]).setSortName($scope.languageColumn);

                var nameColumn = new DatabaseTranslatedColumn($filter, 'global.literals.name', Language.getActiveColumn());
                var queryActionsColumn = new ActionsColumn($filter('translate')('global.literals.actions'),
                    new ActionButton('global.literals.see', 'openQuery([data])', 'fa-eye'),
                    [new ActionButton('global.literals.remove', 'deleteQuery([data])')]);
                
                var profilesRender = function(data, type, full, meta) {
                	var profiles = $linq(full.profiles).select("x => x.profile").toArray();
                	var profilesNames = '';
                    angular.forEach(profiles, function (val, key) {
                        if(profilesNames != '') {
                        	profilesNames += ', ';
                        }
                        profilesNames += val[$scope.languageColumn];
                    });

                    return profilesNames;
                }

                $scope.tableQuery = {
                    dtColumns: [
                        DTColumnBuilder.newColumn('id').withTitle("id").withOption('width', '6%'),
                        DTColumnBuilder.newColumn(nameColumn.getColumn()).withTitle(nameColumn.getTitle()).renderWith(nameColumn.getRender),
                        DTColumnBuilder.newColumn('profiles').withOption('width', '40%').withTitle($filter('translate')('global.literals.profiles')).renderWith(profilesRender).notSortable(),
                        DTColumnBuilder.newColumn('id').withOption('className', 'text-center').withOption('width', '90px').withTitle(queryActionsColumn.getTitle()).renderWith(queryActionsColumn.getRender).notSortable()
                    ],
                    filterCall: $scope.tfilter.getFilterCall(),
                    filterCallAux: {loggedUser: $rootScope.LoggedUser.id, langColumn: $scope.languageColumn},
                    reloadInternalData: function (resetPaging) {
                        this.filterCall = $scope.tfilter.getFilterCall();
                        this.filterCallAux = $scope.tfilter.getFilterCallAux();
                        this.filterCallAux.loggedUser = $rootScope.LoggedUser.id;
                        this.filterCallAux.langColumn = $scope.languageColumn;
                        this.table.reloadData(function callbackInstance(json) {}, resetPaging);
                    }
                };

                HeaderService.onChangeState($scope, function (message) {
                    if(message.state.name == 'annexa.admin.query') {
                        $rootScope.subHeadButtons = [
                            new HeadButtonSearch("#tableFilter"),
                            new HeadButton('btn primary breadDivButton m-r-sm',undefined,'#admin-query','fa fa-plus','global.commonAdmin.modal.query.titleNew',undefined,'openNewQuery')
                        ];
                    }
                });

                HeaderService.changeState($state.current);

                $scope.doSearchFilter = function() {
                    $scope.tableQuery.reloadInternalData(true);
                };

                $scope.deleteQuery = function(id) {
                    if(id) {
                        RestService.checkDelete('./api/query/check_delete/' + id)
                            .then(function(data) {
                                if(data) {
                                    RestService.delete('./api/query/' + id)
                                        .then(function(data) {
                                            $scope.doSearchFilter();
                                        }).catch(function (error) {
                                        console.log(error);
                                    });
                                }
                            }).catch(function(error) {
                                DialogsFactory.error(ErrorFactory.getErrorMessage('query', 'checkDelete', error.data), $filter('translate')('DIALOGS_ERROR_MSG'));
                            });
                    }
                };

                $scope.openNewQuery = function () {
                    var modal = angular.copy(CommonAdminModals.queryNew);
                    modal.annexaFormly.model = {};
                    modal.annexaFormly.model.modal_body = {column2:{queryParameters:[]}};
                    modal.annexaFormly.options = {};
                    modal.annexaFormly.options.formState = { readOnly: false};
                    modal.extra = $scope.tableQuery;
                    
                    AnnexaFormlyFactory.showModal('modalQuery', modal, $scope.saveNewQuery, false, undefined);
                };

                $scope.openQuery = function (id) {
                    if(id) {
                        RestService.findOne('Query', id)
                            .then(function(data) {
                                var modal = angular.copy(CommonAdminModals.queryEdit);
                                modal.data = JSOG.decode(data.data);
                                modal.alerts = [];
                                if (modal.data && !modal.data.valid)
                                    modal.alerts.push({ msg: $filter('translate')("global.validation.query")});

                                modal.data.reloadFunction = function(id){
                                    RestService.findOne('Query', id).then(function(data) {
                                        modal.alerts.length = 0;
                                        if(data && data.data && !data.data.valid){
                                            modal.alerts.push({ msg: $filter('translate')("global.validation.query")});
                                        }
                                    }).catch(function(error){});
                                };

                                modal.data.parameters = $linq(modal.data.parameters).orderBy("x => x.viewOrder").toArray();
                                modal.queryParameters =  modal.data.parameters;
                                modal.languageColumn = Language.getActiveColumn();
                                modal.extra = $scope.tableQuery;

                                modal.connectorName = 'name';
                                modal.queryConnectors = new SortedArray(AdminFactory.queryConnectors, modal.connectorName).sort();
                        		modal.connectionOptions = [{id: 'local', value: $filter('translate')('global.querys.connectors.local')}, {id: 'remote', value: $filter('translate')('global.querys.connectors.remote')}];
                                modal.connection = modal.data.connector ? 'remote' : 'local';
                        		
                                modal.printConnector = function (model) {
                                	if (model) {
                                		return model.name;
                                	}
                                }
                                
                                modal.data.profiles.profile = $linq(modal.data.profiles).select("x => x.profile").toArray();

                                modal.printProfiles = function (model) {
                                    var perfiles = '';
                                    angular.forEach(model, function (val, key) {
                                        if(perfiles != '') {
                                            perfiles += ', ';
                                        }
                                        perfiles += val[$scope.languageColumn];
                                    });

                                    return perfiles;
                                };

                                modal.profiles = GlobalDataFactory.allProfiles;
                                modal.updateQuery = $scope.updateQuery;
                                modal.extraSubmitLabel = 'global.querys.execute.execute';
	                            modal.extraSubmitFunction = function() {
	                            	if(modal.data && modal.data.valid){
		                            	RestService.findOne('Query', id)
		                                .then(function(data) {
		                                    if(data && data.data) {
		                                        var query = JSOG.decode(data.data);
		                                        var modalExecute = angular.copy(QuerysModals.queryExecute);
		                                        modalExecute.annexaFormly.model = {};
		                                        modalExecute.annexaFormly.model.row1 =  {name: query[$scope.languageColumn], description:query.description, query:query.query};
		                                        modalExecute.annexaFormly.model.additionalFilter =  {};
		                                        modalExecute.annexaFormly.options = {};
		                                        modalExecute.annexaFormly.options.formState = {readOnly: false};
		                                        if(modalExecute.annexaFormly.fields && modalExecute.annexaFormly.fields.length > 2 && modalExecute.annexaFormly.fields[2].fieldGroup && modalExecute.annexaFormly.fields[2].fieldGroup.length > 0 && modalExecute.annexaFormly.fields[2].fieldGroup[0].templateOptions){
		                                        	modalExecute.annexaFormly.fields[2].fieldGroup[0].templateOptions.query = query;
		                                        }
		                                        var parameters = $linq(query.parameters).orderBy("x => x.viewOrder").toArray();
		                                        _.forEach(parameters, function (field, index) {
		                                            var fieldClass = 'col-sm-4';
		                                            if (index % 3 === 0) {
		                                                fieldClass += ' col-0';
		                                            }
		                                            var newField = QueryFactory.createQueryParameterField(field, false, false, fieldClass);
		                                            if(newField){
		                                            	modalExecute.annexaFormly.fields[1].fieldGroup.push(newField);
		                                                if(field.queryParameter.queryParameterType == 'DATE' && field.value) {
		                                                    var dateParts = field.value.split("/");
	                                                        var dateObject = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
	                                    					if(dateObject != "Invalid Date") {
	                                    						modalExecute.annexaFormly.model.additionalFilter[newField.key] = dateObject;
	                                    					} else {
	                                    						dateParts = HelperService.dateMaskToDate(dateParts);
	                                    						modalExecute.annexaFormly.model.additionalFilter[newField.key] = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
	                                    					}
		                                                }else if(field.queryParameter.queryParameterType == 'INTEGER' && field.value){
		                                                	modalExecute.annexaFormly.model.additionalFilter[newField.key] =  parseInt(field.value);
		                                                }else if(field.queryParameter.queryParameterType == 'DOUBLE'&& field.value){
		                                                    var valueLiteral_parts = (field.value).split('.');
		                                                    if(valueLiteral_parts && valueLiteral_parts.length > 0){
		                                                        if(valueLiteral_parts[1]){
		                                                        	modalExecute.annexaFormly.model.additionalFilter[newField.key] =  parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0."+valueLiteral_parts[1]).toFixed(2));
		                                                        }else{
		                                                        	modalExecute.annexaFormly.model.additionalFilter[newField.key] =  parseFloat(parseFloat(valueLiteral_parts[0]).toLocaleString() + ".00");
		                                                        }
		                                                    }
		                                                }else if(field.queryParameter.queryParameterType == 'BOOLEAN'){
		                                                    if(field.value && field.value != '-1'){
		                                                    	modalExecute.annexaFormly.model.additionalFilter[newField.key] = field.value;
		                                                    }
		                                                }else if(field.queryParameter.queryParameterType == 'BOOLEAN_NULL'){
                                                            if(field.value && field.value != '-1'){
                                                                modalExecute.annexaFormly.model.additionalFilter[newField.key] = field.value;
                                                            }else{
                                                                modalExecute.annexaFormly.model.additionalFilter[newField.key] = undefined;
                                                            }
                                                        }else if(field.queryParameter.queryParameterType == 'CURRENT_USER' && loggedUser.id){
                                                        	modalExecute.annexaFormly.model.additionalFilter[newField.key] =  parseInt(loggedUser.id);
                                                        }else if(field.value){
		                                                	modalExecute.annexaFormly.model.additionalFilter[newField.key] = field.value;
		                                                }
		                                            }
		                                        });
		                                        modalExecute.extra = ((query && query.id)?query.id:undefined);
		                                        $scope.$on('executeQueryResultError',function(event,args){
		                                            if(args && args.queryId && args.queryId == query.id) {
		                                            	if (args.error && args.error.status == 500) {
		                                            		modalExecute.alerts.push({msg: $filter('translate')('global.querys.execute.queryError'), msgAux:((args.error.message)?args.error.message : undefined), show:function(alert){if(alert){alert.showAux = ((alert.showAux)?false:true)}}, showAux:false});
		                                            	} else {
		                                            		modalExecute.alerts.push({msg: $filter('translate')('global.querys.execute.error')});
		                                            	}
		                                            }
		                                        });
		                                        AnnexaFormlyFactory.showModal('modalQueryExecute', modalExecute, $scope.executeQuery, false, undefined, $filter('translate')('global.querys.execute.execute'));
		                                    }
		                                });
	                            	}else{
	                            		DialogsFactory.error($filter('translate')("global.validation.query"), $filter('translate')('DIALOGS_ERROR_MSG'));
	                            	}
	                            }
	                            
	                            modal.openModalCronSchedule = function() {
	                                var modalCron = angular.copy(QuerysModals.cronScheduleForm);
	                                var cronSchedule = modal.data.cronSchedule;
	                                if (!cronSchedule) {
	                                	cronSchedule = {};
	                                }
	                                modalCron.cronScheduleId = cronSchedule.id;
	                                modalCron.objectId = modal.data.id;
	                                
	                                var emailsToBtn = $linq(modalCron.annexaFormly.fields[3].fieldGroup).firstOrDefault(undefined, "x => x.key == 'emailsToBtn'");
	                                if(emailsToBtn && emailsToBtn.templateOptions){
	                                	emailsToBtn.templateOptions.executeFunction = function(){
	                                		var addMailTo = function(){
	                                			if(this.annexaFormly.model.modal_body.email){
	                                				 var emailsTO = $linq(modalCron.annexaFormly.fields[2].fieldGroup).firstOrDefault(undefined, "x => x.key == 'emailsTO'");
	                                				 if(emailsTO && emailsTO.templateOptions && emailsTO.templateOptions.labels){
	                                					if(!_.contains(emailsTO.templateOptions.labels, this.annexaFormly.model.modal_body.email)){
	 	                                					emailsTO.templateOptions.labels.push(this.annexaFormly.model.modal_body.email);
	                                					}
	 	                                				this.close();
	                                				 }else{
	 	                                				this.close();
	                                				 }
	                                			}else{
	                                				this.close();
	                                			}
	                                		}
	                                		var modalmail = angular.copy(QuerysModals.addMail);
	                                		modalmail.annexaFormly.model = {};
	                                		AnnexaFormlyFactory.showModal('modalQueryMail', modalmail, addMailTo, false);
	                                	}
	                                }
	                                var emailsCCBtn = $linq(modalCron.annexaFormly.fields[3].fieldGroup).firstOrDefault(undefined, "x => x.key == 'emailsCCBtn'");
	                                if(emailsCCBtn && emailsCCBtn.templateOptions){
	                                	emailsCCBtn.templateOptions.executeFunction = function(){
	                                		var addMailCC = function(){
	                                			if(this.annexaFormly.model.modal_body.email){
	                                				 var emailsCC = $linq(modalCron.annexaFormly.fields[2].fieldGroup).firstOrDefault(undefined, "x => x.key == 'emailsCC'");
	                                				 if(emailsCC && emailsCC.templateOptions && emailsCC.templateOptions.labels){
	                                					if(!_.contains(emailsCC.templateOptions.labels, this.annexaFormly.model.modal_body.email)){
	 	                                					emailsCC.templateOptions.labels.push(this.annexaFormly.model.modal_body.email);
	                                					}
	 	                                				this.close();
	                                				 }else{
	 	                                				this.close();
	                                				 }
	                                			}else{
	                                				this.close();
	                                			}
	                                		}
	                                		var modalmail = angular.copy(QuerysModals.addMail);
	                                		modalmail.annexaFormly.model = {};
	                                		AnnexaFormlyFactory.showModal('modalQueryMail', modalmail, addMailCC, false);
	                                	}
	                                }
	                                var emailsCCOBtn = $linq(modalCron.annexaFormly.fields[3].fieldGroup).firstOrDefault(undefined, "x => x.key == 'emailsCCOBtn'");
	                                if(emailsCCOBtn && emailsCCOBtn.templateOptions){
	                                	emailsCCOBtn.templateOptions.executeFunction = function(){
	                                		var addMailCCO = function(){
	                                			if(this.annexaFormly.model.modal_body.email){
	                                				 var emailsCCO = $linq(modalCron.annexaFormly.fields[2].fieldGroup).firstOrDefault(undefined, "x => x.key == 'emailsCCO'");
	                                				 if(emailsCCO && emailsCCO.templateOptions && emailsCCO.templateOptions.labels){
	                                					 if(!_.contains(emailsCCO.templateOptions.labels, this.annexaFormly.model.modal_body.email)){
	                                						 emailsCCO.templateOptions.labels.push(this.annexaFormly.model.modal_body.email);
	                                					 }
	 	                                				this.close();
	                                				 }else{
	 	                                				this.close();
	                                				 }
	                                			}else{
	                                				this.close();
	                                			}
	                                		}
	                                		var modalmail = angular.copy(QuerysModals.addMail);
	                                		modalmail.annexaFormly.model = {};
	                                		AnnexaFormlyFactory.showModal('modalQueryMail', modalmail, addMailCCO, false);
	                                	}
	                                }
	                                var emailsTO = $linq(modalCron.annexaFormly.fields[2].fieldGroup).firstOrDefault(undefined, "x => x.key == 'emailsTO'");
	                                if(emailsTO && emailsTO.templateOptions){
	                                	emailsTO.templateOptions.labels = [];
	                                	if(cronSchedule.emailsTo){
	                                		var emailsTO_parts = cronSchedule.emailsTo.split(';');
                                            if(emailsTO_parts && emailsTO_parts.length > 0){
                                            	_.forEach(emailsTO_parts, function(emailTO){
                                            		emailsTO.templateOptions.labels.push(emailTO);
                                            	});
                                            }
										}
	                                	emailsTO.templateOptions.deleteObject = function(label,index){
	                                		if(emailsTO.templateOptions && emailsTO.templateOptions.labels && emailsTO.templateOptions.labels.length > index){
	                                			emailsTO.templateOptions.labels.splice(index,1);
	                                		}
	                                	}
	                                }
	                                var emailsCC = $linq(modalCron.annexaFormly.fields[2].fieldGroup).firstOrDefault(undefined, "x => x.key == 'emailsCC'");
	                                if(emailsCC && emailsCC.templateOptions){
	                                	emailsCC.templateOptions.labels = [];
	                                	if(cronSchedule.emailsCc){
	                                		var emailsCC_parts = cronSchedule.emailsCc.split(';');
                                            if(emailsCC_parts && emailsCC_parts.length > 0){
                                            	_.forEach(emailsCC_parts, function(emailCC){
                                            		emailsCC.templateOptions.labels.push(emailCC);
                                            	});
                                            }
										}
	                                	emailsCC.templateOptions.deleteObject = function(label,index){
	                                		if(emailsCC.templateOptions && emailsCC.templateOptions.labels && emailsCC.templateOptions.labels.length > index){
	                                			emailsCC.templateOptions.labels.splice(index,1);
	                                		}
	                                	}
	                                }
	                                var emailsCCO = $linq(modalCron.annexaFormly.fields[2].fieldGroup).firstOrDefault(undefined, "x => x.key == 'emailsCCO'");
	                                if(emailsCCO && emailsCCO.templateOptions){
	                                	emailsCCO.templateOptions.labels = [];
	                                	if(cronSchedule.emailsCco){
	                                		var emailsCCO_parts = cronSchedule.emailsCco.split(';');
                                            if(emailsCCO_parts && emailsCCO_parts.length > 0){
                                            	_.forEach(emailsCCO_parts, function(emailCCO){
                                            		emailsCCO.templateOptions.labels.push(emailCCO);
                                            	});
                                            }
										}
	                                	emailsCCO.templateOptions.deleteObject = function(label,index){
	                                		if(emailsCCO.templateOptions && emailsCCO.templateOptions.labels && emailsCCO.templateOptions.labels.length > index){
	                                			emailsCCO.templateOptions.labels.splice(index,1);
	                                		}
	                                	}
	                                }									
	                                
	                                modalCron.annexaFormly.model = {};
	                                modalCron.annexaFormly.model.row1 = { cronExpression: cronSchedule.cronExpression };
	                                modalCron.annexaFormly.model.row2 = { 
	                                		sentByMail_1: (((cronSchedule.profiles && cronSchedule.profiles.length > 0) || (cronSchedule.emailsTo && cronSchedule.emailsTo !== '') || (cronSchedule.emailsCc && cronSchedule.emailsCc !== '') || (cronSchedule.emailsCco && cronSchedule.emailsCco !== '')) ? true : false)
	                                };
	                                modalCron.annexaFormly.model.row2Mail = { 
	                                		sentByMail_1: (((cronSchedule.profiles && cronSchedule.profiles.length > 0) || (cronSchedule.emailsTo && cronSchedule.emailsTo !== '') || (cronSchedule.emailsCc && cronSchedule.emailsCc !== '') || (cronSchedule.emailsCco && cronSchedule.emailsCco !== '')) ? true : false)
	                                };
	                                modalCron.annexaFormly.model.row2MailBtn = { 
	                                		sentByMail_1: (((cronSchedule.profiles && cronSchedule.profiles.length > 0) || (cronSchedule.emailsTo && cronSchedule.emailsTo !== '') || (cronSchedule.emailsCc && cronSchedule.emailsCc !== '') || (cronSchedule.emailsCco && cronSchedule.emailsCco !== '')) ? true : false)
	                                };
	                                modalCron.annexaFormly.model.row2MailBody = { 
	                                		sentByMail_1: (((cronSchedule.profiles && cronSchedule.profiles.length > 0) || (cronSchedule.emailsTo && cronSchedule.emailsTo !== '') || (cronSchedule.emailsCc && cronSchedule.emailsCc !== '') || (cronSchedule.emailsCco && cronSchedule.emailsCco !== '')) ? true : false),
	                                		body: ((cronSchedule.emailBody)?cronSchedule.emailBody:undefined)
	                                };
	                                modalCron.annexaFormly.model.row2.profilesIds = [];
	                                if (cronSchedule.profiles) {
	                                	modalCron.annexaFormly.model.row2.profilesIds = $linq(cronSchedule.profiles).select("x => x.profile.id").toArray();
	                                }
	                                modalCron.annexaFormly.model.row3 = { 
                                			sentByFTP_1: cronSchedule.host || cronSchedule.port || cronSchedule.userFtp || cronSchedule.passwordFtp || cronSchedule.folder ? true : false,
                                			host: cronSchedule.host,
                                			port: cronSchedule.port,
                                			userFtp: cronSchedule.userFtp,
                                			passwordFtp: cronSchedule.passwordFtp,
                                			folder: cronSchedule.folder
	                                };
	                                modalCron.annexaFormly.options = {};
	                                $scope.$on('cronScheduleQuery', function(event, data) { 
	                                	modal.cronAlerts = [];
	                                	modal.data.cronSchedule = data;

	                                	if (modal.data.cronSchedule) {
		                                    modal.cronAlerts.push({ msg: 'global.commonAdmin.modal.cron.alertQueryReady', type: 'success', link: function(){ modal.openModalCronSchedule() }, labelLink: 'global.commonAdmin.modal.cron.configure' })

		                                    _.forEach(data.profiles, function(dataProfileCS) {
		                                    	_.forEach(modal.data.cronSchedule.profiles, function(modalDataProfileCS) {
		                                    		if (modalDataProfileCS.profile.id === dataProfileCS.profile.id) {
		                                    			modalDataProfileCS.profile = $linq(modal.profiles).firstOrDefault(undefined, "x => x.id == " + dataProfileCS.profile.id);
		                                    		}
		                                    	});
		                                    });
	                                	} else {
	                                		modal.cronAlerts.push({ msg: 'global.commonAdmin.modal.cron.alertQueryNotReady', type: 'info', link: function(){ modal.openModalCronSchedule() }, labelLink: 'global.commonAdmin.modal.cron.schedule' });
	                                	}
	                                });
	                                
	                                if (cronSchedule.id) {
		                                modalCron.additionalButton = {
		                                        label: 'global.commonAdmin.modal.cron.notScheduleExecution',
		                                        click: function () {
		                                        	DialogsFactory.confirm('global.commonAdmin.modal.cron.notScheduleExecution', 'global.commonAdmin.modal.cron.notScheduleExecutionConfirm', 'zMax3', 'zMax')
		                                            .then(function (data) {
			                                            RestService.delete('./api/query/cronSchedule/' + cronSchedule.id)
			                                            .then(function(data) {
			                                            	$scope.$broadcast('cronScheduleQuery', undefined);
			                                            	modalCron.close();
			                                            }).catch(function (error) {
			                                            	console.log(error);
			                                            });
		                                           }).catch(function (data) {
		                                	               //Empty
		                                	       });
		                                       	}
		                                    }
	                                }
	                                AnnexaFormlyFactory.showModal('modalQueryCron', modalCron, $scope.scheduleCronQuery, false, undefined, 'global.commonAdmin.modal.cron.scheduleExecution');
	                            }
                                modal.cronAlerts = [];
                                RestService.findByUrl('./api/query/findByObjectTypeAndObjectId?objectType=QUERY&objectId=' + id).then(function(response) {
                                    if(response && response.data) {
                                    	modal.data.cronSchedule = JSOG.decode(response.data);
                                    	modal.cronAlerts.push({ msg: 'global.commonAdmin.modal.cron.alertQueryReady', type: 'success', link: function(){ modal.openModalCronSchedule() }, labelLink: 'global.commonAdmin.modal.cron.configure' });
                                    } else {
                                    	modal.cronAlerts.push({ msg: 'global.commonAdmin.modal.cron.alertQueryNotReady', type: 'info', link: function(){ modal.openModalCronSchedule() }, labelLink: 'global.commonAdmin.modal.cron.schedule' });
                                    }
                                    $scope.modalQuery = modal;
                                    AnnexaModalFactory.showModal('modalQueryEdit', modal);
                                }).catch(function (response) {
                                });
                            });
                    }
                };
                
                $scope.scheduleCronQuery = function(){
                    if (this.annexaFormly.form.$valid) {
                    	var self = this;
                    	var model = this.annexaFormly.model;
                    	
                        var profiles = [];
                        if (model.row2.sentByMail_1) {
                        	_.forEach(model.row2.profiles, function(item) {
                        		if (item.id) {
                        			profiles.push({ profile: { id: item.id } });
                        		} else {
                        			profiles.push({ profile: { id: item } });
                        		}
                            });
                        }
                        var emailsTo = "";
                        var emailsCc = "";
                        var emailsCco = "";
                        var emailsBody = "";
                        if (model.row2.sentByMail_1) {
                        	var emailsTOField = $linq(this.annexaFormly.fields[2].fieldGroup).firstOrDefault(undefined, "x => x.key == 'emailsTO'");
                            if(emailsTOField && emailsTOField.templateOptions && emailsTOField.templateOptions.labels ){
	                            _.forEach(emailsTOField.templateOptions.labels, function(item) {
	                        		emailsTo = emailsTo +((emailsTo && emailsTo !== "")?";":"")+ item;
	                            });
                            }
                            var emailsCCField = $linq(this.annexaFormly.fields[2].fieldGroup).firstOrDefault(undefined, "x => x.key == 'emailsCC'");
                            if(emailsCCField && emailsCCField.templateOptions && emailsCCField.templateOptions.labels ){
	                            _.forEach(emailsCCField.templateOptions.labels, function(item) {
	                            	emailsCc = emailsCc +((emailsCc && emailsCc !== "")?";":"")+ item;
	                            });
                            }
                            var emailsCCOField = $linq(this.annexaFormly.fields[2].fieldGroup).firstOrDefault(undefined, "x => x.key == 'emailsCCO'");
                            if(emailsCCOField && emailsCCOField.templateOptions && emailsCCOField.templateOptions.labels ){
	                            _.forEach(emailsCCOField.templateOptions.labels, function(item) {
	                            	emailsCco = emailsCco +((emailsCco && emailsCco !== "")?";":"")+ item;
	                            });
                            }
                        	if(model.row2MailBody && model.row2MailBody.body){
                        		emailsBody = model.row2MailBody.body;
                        	}
                        }
                        if (!model.row3.sentByFTP_1) {
                        	model.row3.host = undefined;
                        	model.row3.port = undefined;
                        	model.row3.userFtp = undefined;
                        	model.row3.passwordFtp = undefined;
                        	model.row3.folder = undefined;
                        }
                        
                    	var cronSchedule = {
                    			id: this.cronScheduleId,
                    			objectId: this.objectId,
                    			cronExpression: this.annexaFormly.fields[0].fieldGroup[0].data.cronExpression,
                    			profiles: profiles,
                    			emailsTo: ((emailsTo && emailsTo !== "")?emailsTo:undefined),
                    			emailsCc: ((emailsCc && emailsCc !== "")?emailsCc:undefined),
                    			emailsCco: ((emailsCco && emailsCco !== "")?emailsCco:undefined),
                    			emailBody: ((emailsBody && emailsBody !== "")?emailsBody:undefined),
                    			host: model.row3.host,
                    			port: model.row3.port,
                    			userFtp: model.row3.userFtp,
                    			passwordFtp: model.row3.passwordFtp,
                    			folder: model.row3.folder,
                    			objectType: 'QUERY'
                            };

                    	if (cronSchedule.id) {
                            RestService.update('./api/query/cronSchedule/' + cronSchedule.id, cronSchedule)
		                        .then(function(data) {
		                        	$scope.$broadcast('cronScheduleQuery', data);
		                        	self.close();
		                        }).catch(function (error) {
		                        	console.log(error);
	                        });
                    	} else {
                            RestService.insert('./api/query/cronSchedule', cronSchedule)
		                        .then(function(data) {
		                        	$scope.$broadcast('cronScheduleQuery', data);
		                        	self.close();
		                        }).catch(function(error) {
		                            console.log(error);
	                        });                    		
                    	}
                    }
                };

                $scope.executeQuery = function(){
                    if (this.annexaFormly.form.$valid) {
                        var self = this;
                        var modelAdditionalFilter = this.annexaFormly.model.additionalFilter;
                        modelAdditionalFilter.langColumn = $scope.languageColumn;
                        modelAdditionalFilter.loggedUser = $rootScope.LoggedUser.id;
                        $rootScope.$broadcast('executeQueryResult', {queryId:this.extra, additionalFilter:modelAdditionalFilter, modal:this});
                    }
                };

                $scope.updateQuery = function(val, prop) {
                    var self = $scope.modalQuery;
                    var query = angular.copy($scope.modalQuery.data);
                    self.alerts = [];
                    if (prop == 'profiles') {
                        query[prop] = [];
                        _.forEach(val, function (item) {
                            query[prop].push({profile: {id: item.id}});
                        });
                    } else if (prop == 'connection') {
                    	if (val == 'local') {
                    		query.connector = undefined;	
                    	}
                    } else if(prop != 'language1' && prop != 'language2' && prop != 'language3') {
                        query[prop] = val;
                    }

                    RestService.update('./api/query/' + query.id, query)
                        .then(function(data) {
                            self.extra.reloadInternalData(true);
                            if (self.data.query)
                                self.data.query = data.query;
                            if (data && !data.valid)
                                self.alerts.push({ msg: $filter('translate')("global.validation.query")});
                            if (!data.connector)
                            	self.data.connector = data.connector;
                        }).catch(function (error) {
                        console.log(error);
                    });

                };

                $scope.saveNewQuery = function(){
                    if (this.annexaFormly.form.$valid) {
                        var self = this;
                        var model = this.annexaFormly.model.modal_body.column1;

                        var profiles = [];
                        _.forEach(model.profiles, function(item) {
                            profiles.push({ profile: { id: item } });
                        });

                        var query = {
                            language1: model.language1,
                            language2: model.language2,
                            language3: model.language3,
                            description: model.description,
                            query: model.query,
                            connector: model.connector ? { id: model.connector } : undefined,
                            parameters: [],
                            profiles: profiles
                        };

                        angular.forEach(this.annexaFormly.fields[0].fieldGroup[1].fieldGroup[0].data.queryParameters, function (value, key) {
                            var field = {
                                queryParameter: value.queryParameter,
                                viewOrder:value.viewOrder,
                                value: value.value
                            };

                            query.parameters.push(field);
                        });

                        RestService.insert('./api/query', query)
                            .then(function(data) {
                                self.extra.reloadInternalData(true);
                                self.close();
                                if (data && data.id)
                                    $scope.openQuery(data.id);
                            }).catch(function(error) {
                                console.log(error);
                            });
                    }
                };
            }]);