angular
    .module('annexaApp')
        .controller('AuditAutomatismsController',['$scope', '$rootScope', 'AnnexaFormlyFactory', 'AnnexaModalFactory', 'CommonAdminModals', 'HeaderService', '$state', 'TableFilter', 'DTColumnBuilder', '$filter', 'RestService', 'Language', 'HelperService', 'ErrorFactory','AdminFactory', 'DialogsFactory', 'GlobalDataFactory', 'QuerysModals', 'QueryFactory', 'CustomFieldFactory',
            function($scope, $rootScope, AnnexaFormlyFactory, AnnexaModalFactory, CommonAdminModals, HeaderService, $state, TableFilter, DTColumnBuilder, $filter, RestService, Language, HelperService, ErrorFactory, AdminFactory, DialogsFactory, GlobalDataFactory, QuerysModals, QueryFactory, CustomFieldFactory) {
                $scope.showLoadingdiv = false;
                $scope.languageColumn = Language.getActiveColumn();
                $scope.addToFilter = {filter:[], filterAux:[]};
                $scope.filter = [
                	{ id: 'automatism', type: 'text', order: 1, label: 'global.querys.automatisms.automatism', callAux: true},
                	{ id: 'actionCatalog', type: 'select', order: 2, label: 'global.literals.action', dataType: 'LOCAL', callAux: true, data: new SortedArray(AdminFactory.actionCatalogs, $scope.languageColumn).sort(), addAll: true, nameProperty: $scope.languageColumn},
                	{ id: 'executionDate', type: 'dateRange', order: 3, label: 'global.literals.executionDate', callAux: true},
    				{ id: 'auditObjectId', type: 'text', order: 4, label: 'global.literals.objectId', callAux: true},
    				{ id: 'externalObjectId', type: 'text', order: 5, label: 'global.literals.externalObjectId', callAux: true},
    				{ id: 'executedOK', type: 'checkbox', order: 6, label: 'global.literals.executedOK', callAux: true}
                ]
                $scope.additionalColumns = [
                	{ id: 'automatism.'+$scope.languageColumn, title: $filter('translate')('global.querys.automatisms.automatism')},
                	{ id: 'automatism.actionCatalog.'+$scope.languageColumn, title: $filter('translate')('global.literals.action')},
                	{ id: 'executionDate', column:  new DateTimeColumn($filter, 'global.literals.executionDate') },
    				{ id: 'error', column: new BooleanInverseColumn($filter, 'global.literals.executedOK', 'error')},
    				{ id: 'id', width: '12%', column: new ActionsColumn($filter('translate')('global.literals.actions'), new ActionButton('global.literals.see', 'viewElement([data])', 'fa-eye'), []), sortable: false}
                ]
                
                $scope.viewElement = function (id) {
                	RestService.findOne("AutomatismAudit", id).then(function (data) {
                		var closeFunction = function(){
        					var modalExpAux = this;
        					modalExpAux.close();
        				}
                		
                		var modal = angular.copy(CommonAdminModals.modalAuditAutomatism);
    					modal.annexaFormly.options = {};
    					modal.annexaFormly.model = {modal_body:{}};
    					var auditAutomatismField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'auditAutomatism'");
    					if(auditAutomatismField) {
    						auditAutomatismField.data.auditAutomatism = JSOG.decode(data.data);
    					}
    					AnnexaFormlyFactory.showModal('modalAutomatismAudit', modal, closeFunction, false);		
                    }).catch(function (error) {
                    	console.log(error);
                    })
                };
        	   
    }]);