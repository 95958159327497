/**
 * Created by osirvent on 07/06/2016.
 */
angular
    .module('annexaApp')
    .controller('UnguidedProcedureController',['$scope', '$rootScope', 'AnnexaFormlyFactory', 'CommonAdminModals', 'HeaderService', '$state', 'TableFilter', 'DTColumnBuilder', '$filter', '$compile', 'RestService', 'Language', 'CommonAdminService', 'apiAdmin', 'CustomFieldFactory', 'HelperService', 'CommonService', 'AdminFactory', 'dialogs', 'GlobalDataFactory', 'CacheFactory', 'AnnexaModalFactory', 'DialogsFactory',
        function($scope, $rootScope, AnnexaFormlyFactory, CommonAdminModals, HeaderService, $state, TableFilter, DTColumnBuilder, $filter, $compile, RestService, Language, CommonAdminService,apiAdmin, CustomFieldFactory, HelperService, CommonService, AdminFactory, dialogs, GlobalDataFactory, CacheFactory, AnnexaModalFactory, DialogsFactory) {
            $scope.showLoadingdiv = false;
			$scope.councillors = GlobalDataFactory.councillors;
			$scope.councillorsTree = GlobalDataFactory.councillorsTree;
            $scope.familyProcedures = GlobalDataFactory.familyProcedures;
            $scope.familiesProcedureTree = GlobalDataFactory.familyProceduresTree;
            $scope.classificationTree = GlobalDataFactory.archiveClassificationsTree;
            $scope.classificationBoxs = GlobalDataFactory.archiveClassifications;
            $scope.profilesProcedure = [];
            $scope.rolesInterested = [];
            $scope.showClassificationList = apiAdmin.showClassificationList;
            $scope.expirationTypeList = apiAdmin.expirationTypeList;
            $scope.customFieldRequiredList = apiAdmin.customFieldRequiredList;
            $scope.showSubjectList = apiAdmin.showSubjectList;
            $scope.yesNoList = apiAdmin.yesNoBoolean;
            $scope.internalControl = false;
            if ($rootScope.app.configuration && $rootScope.app.configuration.secretary_properties && $rootScope.app.configuration.secretary_properties.internal_control) {
            	$scope.internalControl = true;
            }
            $scope.inspectionInterventionTypes = apiAdmin.inspectionInterventionTypes;
            $scope.expirationTypeList = apiAdmin.expirationTypeList;
            $scope.yesNoBooleanNull = apiAdmin.yesNoBooleanNull;
            $scope.customFields = [];
            $scope.languageColumn = Language.getActiveColumn();
            $scope.descriptionLanguageColumn = "";
            $scope.informationLanguageColumn = "";
            $scope.accessLevelNotificationList = apiAdmin.accessLevelNotificationList;
            if($scope.languageColumn) {
                $scope.descriptionLanguageColumn = "descriptionLanguage" + $scope.languageColumn.substr($scope.languageColumn.length - 1);
                $scope.informationLanguageColumn = "informationLanguage" + $scope.languageColumn.substr($scope.languageColumn.length - 1);
            }
            $scope.tfilter = new TableFilter();
            $scope.tfilter.addElement('family', 'select-tree', 0, 'global.literals.family', undefined, GlobalDataFactory.familyProcedures, undefined, undefined, false, undefined, $scope.languageColumn);
            $scope.tfilter.addElement('id', 'text', 1, 'global.literals.code');
            $scope.tfilter.addElement('acronym', 'text', 2, 'global.literals.acronym');
            $scope.tfilter.addElement(Language.getActiveColumn(), 'text', 3, 'global.literals.procedure');
            $scope.tfilter.loadLocalData([],undefined, undefined, $scope.app.language);

            $scope.filterData = { loggedUser: $rootScope.LoggedUser.id, guided:false};

            $scope.dtDefUnguidedProcedure = new TableData('tram', 'Procedure', './api/tram/byfilter').setSortName('family').setSort([[1, 'asc']]);

            $scope.profilesProcedure = angular.copy(AdminFactory.profiles);
            $scope.rolesInterested = angular.copy(AdminFactory.rolesInterested);
            
            if(!AdminFactory.usersList || AdminFactory.usersList.length == 0) {
            	AdminFactory.getUsersList('procedure');
            }

            CustomFieldFactory.getCustomFields().then(function (data) {
                if (data){
                    var response = JSOG.decode(data);
                    $scope.customFields.length = 0;
                    $scope.customFields.push({id:"-1", language1:$filter('translate')('global.literals.new'), language2:$filter('translate')('global.literals.new'), language3:$filter('translate')('global.literals.new')});
                    response.customFields = $linq(response.customFields).orderBy("x => x." + $scope.languageColumn, HelperService.caseInsensitiveOrNullComparer).toArray();
                    angular.forEach(response.customFields, function(value, key){
                        var aux = HelperService.findFirstElementWhere(response.customFieldsDisable, 'id', value.id, -1);
                        if(aux != -1){
                            value.disableElement = true;
                        }
                        $scope.customFields.push(value);
                    });
                }
            });

            var FamilyColumn = new DatabaseTranslatedColumn($filter, 'global.literals.family', Language.getActiveColumn());
            var ProcedureColumn = new DatabaseTranslatedColumn($filter, 'global.literals.procedure', Language.getActiveColumn());
            var ProcedureActionsColumn = new ActionsColumn($filter('translate')('global.literals.actions'),
                new ActionButton('global.literals.see', 'annexa.admin.unguided_procedure.edit_unguided_procedure({ procedure: \'[data]\'})', 'fa-eye', true),
                [new ActionButton('global.literals.remove', 'deleteProcedure([data])')]);

            var getFilterCall = function(){
                var filterCall = $scope.tfilter.getFilterCall();
                if($scope.filterData.status && $scope.filterData.status != ''){
                    filterCall.docStatus = $scope.filterData.status;
                }
                if($scope.filterData.guided && $scope.filterData.guided != ''){
                    filterCall.guided = $scope.filterData.guided;
                }else if($scope.filterData.guided == false){
                    filterCall.guided = $scope.filterData.guided;
                }
                return filterCall;
            }

            $scope.tableUnguidedProcedure = {
                dtColumns: [
                    DTColumnBuilder.newColumn('id').withTitle("id").withOption('width', '6%'),
                    DTColumnBuilder.newColumn('family.' + FamilyColumn.getColumn()).withTitle(FamilyColumn.getTitle()).renderWith(FamilyColumn.getRender),
                    DTColumnBuilder.newColumn('id').withTitle($filter('translate')('global.literals.code')),
                    DTColumnBuilder.newColumn('acronym').withTitle($filter('translate')('global.literals.acronym')),
                    DTColumnBuilder.newColumn(ProcedureColumn.getColumn()).withTitle(ProcedureColumn.getTitle()),
                    DTColumnBuilder.newColumn('id').withOption('className', 'text-center').withOption('width', '130px').withTitle(ProcedureActionsColumn.getTitle()).renderWith(ProcedureActionsColumn.getRender).notSortable()
                ],
                filterCall: getFilterCall(),
                filterCallAux: {loggedUser: $rootScope.LoggedUser.id, applyPermissions: false, langColumn: $scope.languageColumn, admin: true },
                reloadInternalData: function (resetPaging) {
                    this.filterCall = getFilterCall();
                    this.filterCallAux = $scope.tfilter.getFilterCallAux();
                    this.filterCallAux.loggedUser = $rootScope.LoggedUser.id;
                    this.filterCallAux.applyPermissions = false;
                    this.filterCallAux.langColumn = $scope.languageColumn;
                    this.filterCallAux.admin = true;
                    if(this.table.reloadData) {
                        this.table.reloadData(function callbackInstance(json) {}, resetPaging);
                    }
                }
            }

            HeaderService.onChangeState($scope, function (message) {
                if(message.state.name == 'annexa.admin.unguided_procedure') {
                    $rootScope.subHeadButtons = [
                        new HeadButtonSearch("#tableFilter"),
                        new HeadButton('btn primary breadDivButton mr-sm-pt',undefined,'#admin-unguidedProcedure','fa fa-plus','global.commonAdmin.modal.unguidedProcedure.titleNew',undefined,'openNewProcedure'),
                        new HeadButton('btn primary breadDivButton mr-sm-pt',undefined,'#admin-unguidedProcedure','fa fa-upload','global.literals.import',undefined,'importUnguidedProcedure')
                    ];
                    var customFields = angular.copy(GlobalDataFactory.customFields);
                    $scope.customFields.length = 0;
                    $scope.customFields.push({id:"-1", language1:$filter('translate')('global.literals.new'), language2:$filter('translate')('global.literals.new'), language3:$filter('translate')('global.literals.new')});
                    customFields = $linq(customFields).orderBy("x => x." + $scope.languageColumn, HelperService.caseInsensitiveOrNullComparer).toArray();
                    angular.forEach(customFields, function(value, key) {
                        $scope.customFields.push(value);
                    });
                }
            })

            HeaderService.changeState($state.current);

            $scope.deleteProcedure = function(id) {
                if(id) {
                    var dlg = dialogs.confirm(
                        $filter('translate')('global.literals.deleteProcedure'),
                        $filter('translate')('global.literals.confirmDeleteProcedureMessage'),
                        {
                            animation: true,
                            backdrop: 'static',
                            keyboard: true,
                            size: ''
                        }
                    );

                    dlg.result.then(function (btn) {
                        CommonAdminService.deleteProcedure(id).then(function (data) {
                            $scope.doSearchFilter();
                            if(GlobalDataFactory.procedures){
                            	GlobalDataFactory.procedures.length = 0;
                            }else{
                            	GlobalDataFactory.procedures = [];
                            }
                            if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=Procedure&origin=globalData')){
                            	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=Procedure&origin=globalData')
                            }
                            GlobalDataFactory.loadGlobalData();
                        }).catch(function (error) {
                            var err = dialogs.error(
                                $filter('translate')('DIALOGS_ERROR_MSG'),
                                $filter('translate')(error.message),
                                {
                                    animation: true,
                                    backdrop: 'static',
                                    keyboard: true,
                                    size: ''
                                }
                            );
                        });
                    }, function (btn) {
                    });


                }
            };

            $scope.openNewProcedure = function () {
                $state.transitionTo('annexa.admin.unguided_procedure.new_unguided_procedure');
            }

            $scope.doSearchFilter=function(queued) {
                $scope.tableUnguidedProcedure.reloadInternalData(true);
            }
                        
            $scope.importUnguidedProcedure = function () {  
            	AdminFactory.importDataAdmin($scope, "UNGUIDED_PROCEDURES", $scope.tableUnguidedProcedure);
            }
            
    }])
    .controller('NewUnguidedProcedureController',['$scope', '$stateParams', '$timeout', '$rootScope', 'AnnexaFormlyFactory', 'CommonAdminModals', 'HeaderService', '$state', 'TableFilter', 'DTColumnBuilder', '$filter', '$compile', 'RestService', 'Language', 'CommonAdminService', 'apiAdmin', 'HelperService', 'ErrorFactory', '$translate', 'AdminFactory', 'GlobalDataFactory', 'CacheFactory', 'DialogsFactory', 'TramNewFactory',
        function($scope, $stateParams, $timeout, $rootScope, AnnexaFormlyFactory, CommonAdminModals, HeaderService, $state, TableFilter, DTColumnBuilder, $filter, $compile, RestService, Language, CommonAdminService, apiAdmin, HelperService, ErrorFactory, $translate, AdminFactory, GlobalDataFactory, CacheFactory, DialogsFactory, TramNewFactory) {
            $scope.alerts = [];
            $scope.closeAlert = function (index) {
                $scope.alerts.splice(index, 1);
            }
			$scope.emgdeproc = {};
			$scope.documentationToProvide = [];
			$scope.createTransactions = [];
            $scope.formEUP = new AnnexaFormly();
            $scope.customFieldsProcedure = [];
            $scope.initCfieldWhenSaved = false;
            
            if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.councillor_proposal_type && $rootScope.app.configuration.councillor_proposal_type.active) {
				$scope.isActiveCouncillor = true;
            }else{
            	$scope.isActiveCouncillor = false;
			}
            HeaderService.onChangeState($scope, function(message) {
                if(message.state.name == 'annexa.admin.unguided_procedure.new_unguided_procedure') {
                    $rootScope.subHeadButtons = [
                        new HeadButton('btn grey-500 text-white breadDivButton ', undefined, '#admin-newUunguidedProcedure',undefined,'global.literals.cancel',undefined,'cancel'),
                        new HeadButton('btn primary breadDivButton m-r-sm',undefined,'#admin-newUunguidedProcedure','fa-floppy-o','global.literals.save',undefined,'save')
                    ];
                }
            });
            
            if(!AdminFactory.usersList || AdminFactory.usersList.length == 0) {
            	AdminFactory.getUsersList('procedure');
            }
            
            HeaderService.changeState($state.current);

            $scope.save = function() {
                $timeout(function () {
                    angular.element('#smFormEUP').trigger('click');
                })
            };
            $scope.transactionTypes = [];
            if(GlobalDataFactory.transactiontypes && GlobalDataFactory.transactiontypes.length > 0){
                $scope.transactionTypes = $linq(GlobalDataFactory.transactiontypes).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).where("x => x.startType == true && x.active == 1").toArray();
            }
            $scope.tramitationTypes = GlobalDataFactory.dossierTramitationTypes;
            
            $scope.subproceduresList = [];
            if(GlobalDataFactory.procedures && GlobalDataFactory.procedures.length > 0) {
                $scope.subproceduresList = $linq(GlobalDataFactory.procedures).where("x => x.procedureType == 'SUBPROCEDURE' || x.procedureType == 'PROCEDURE_SUBPROCEDURE'").toArray();
            }

            var validateProcedureClassifications = function() {
                var ret = true;

                var removeAlert = function() {
                    var indexAlert = $linq($scope.alerts).indexOf("x => x.msg == 'global.errors.procedureClassifications'");

                    if(indexAlert != -1) {
                        $scope.alerts.splice(indexAlert, 1);
                    }
                };

                var addAlert = function () {
                    var indexAlert = $linq($scope.alerts).indexOf("x => x.msg == 'global.errors.procedureClassifications'");

                    if(indexAlert == -1) {
                        $scope.alerts.push({ msg: 'global.errors.procedureClassifications' })
                    }
                };

                if($scope.formEUP.model.showClassification != 'DISABLED') {
                    if (!$scope.formEUP.model.procedureClassifications) {
                        ret = false;
                        addAlert();
                    } else if($scope.formEUP.model.procedureClassifications.length == 0) {
                        ret = false;
                        addAlert();
                    } else {
                        removeAlert();
                    }
                } else {
                    removeAlert();
                }

                return ret;
            }

            $scope.submitEUP = function () {
                var validPC = validateProcedureClassifications();

                if ($scope.formEUP.form.$valid) {
                    var newProcedure = new procedure($scope.formEUP.model, true, $scope.isActiveCouncillor );
                    if($scope.isActiveCouncillor && newProcedure.organsAgreement && newProcedure.organsAgreement.id && (!newProcedure.organsAgreementDelegationType || !newProcedure.organsAgreementDelegationType.id)){
                    	$scope.alerts.push({ msg: $filter('translate')('global.sec.literals.organWithoutDelegationType') });
                    }else{
                    	if($scope.isActiveCouncillor){
                    		var organsOpinionCouncillorField = $linq($scope.formEUP.fields).firstOrDefault(undefined, "x => x.key == 'organsOpinion'");
	        				if(organsOpinionCouncillorField && organsOpinionCouncillorField.data && organsOpinionCouncillorField.data.organsOpinion){
	        					newProcedure.organsOpinion = organsOpinionCouncillorField.data.organsOpinion;
	        				}
                    	}
                    	if(newProcedure.archiveClassification && newProcedure.archiveClassification.id && newProcedure.archiveClassification.id.$selected){
                    		newProcedure.archiveClassification.id = newProcedure.archiveClassification.id.$selected.id;
                    	}else{
                    		newProcedure.archiveClassification = null;
                    	}
                    	if(newProcedure.family && newProcedure.family.id && newProcedure.family.id.$selected) {
                    		newProcedure.family.id = newProcedure.family.id.$selected.id;
                    	} else {
                    		newProcedure.family = null;
                    	}
                    	if(newProcedure.councillor && newProcedure.councillor.id && newProcedure.councillor.id.$selected) {
                    		newProcedure.councillor.id = newProcedure.councillor.id.$selected.id;
                    	} else {
                    		newProcedure.councillor = null;
                    	}
                    	if($scope.emgdeproc.emgdeSecurityClassification){
                    		newProcedure.emgdeSecurityClassification = $scope.emgdeproc.emgdeSecurityClassification;
                    	}
                    	if($scope.emgdeproc.emgdeSecurityLevel){
                    		newProcedure.emgdeSecurityLevel = $scope.emgdeproc.emgdeSecurityLevel;
                    	}
                    	if($scope.emgdeproc.emgdeAccesType){
                    		newProcedure.emgdeAccesType = $scope.emgdeproc.emgdeAccesType;
                    	}
                    	if($scope.emgdeproc.emgdeLimitCause){
                    		newProcedure.emgdeLimitCause = $scope.emgdeproc.emgdeLimitCause;
                    	}
                    	if($scope.emgdeproc.emgdeLegalLimitCause){
                    		newProcedure.emgdeLegalLimitCause = $scope.emgdeproc.emgdeLegalLimitCause;
                    	}
                    	if($scope.emgdeproc.emgdeReuseCondition){
                    		newProcedure.emgdeReuseCondition = $scope.emgdeproc.emgdeReuseCondition;
                    	}
                    	if($scope.emgdeproc.emgdeValueType){
                    		newProcedure.emgdeValueType = $scope.emgdeproc.emgdeValueType;
                    	}
                    	if($scope.emgdeproc.emgdeRatingTerms){
                    		newProcedure.emgdeRatingTerms = $scope.emgdeproc.emgdeRatingTerms;
                    	}
                    	if($scope.emgdeproc.emgdeSecundaryValue){
                    		newProcedure.emgdeSecundaryValue = $scope.emgdeproc.emgdeSecundaryValue;
                    	}
                    	if($scope.emgdeproc.emgdeOpinionType){
                    		newProcedure.emgdeOpinionType = $scope.emgdeproc.emgdeOpinionType;
                    	}
                    	if($scope.emgdeproc.emgdeOpinionAction){
                    		newProcedure.emgdeOpinionAction = $scope.emgdeproc.emgdeOpinionAction;
                    	}
                    	if($scope.emgdeproc.emgdeOpinionActionTerms){
                    		newProcedure.emgdeOpinionActionTerms = $scope.emgdeproc.emgdeOpinionActionTerms;
                    	}
                    	if($scope.emgdeproc.emgdeTransferArchive){
                    		newProcedure.emgdeTransferArchive = $scope.emgdeproc.emgdeTransferArchive;
                    	}
                    	if($scope.emgdeproc.emgdeTransferTerms){
                    		newProcedure.emgdeTransferTerms = $scope.emgdeproc.emgdeTransferTerms;
                    	}
                    	if($scope.emgdeproc.emgdeEssentialDocument){
                    		newProcedure.emgdeEssentialDocument = $scope.emgdeproc.emgdeEssentialDocument;
                    	}
                    	
                    	newProcedure.proposalSignCircuit = (($scope.formEUP && $scope.formEUP.model && $scope.formEUP.model.proposalSignCircuitConfiguration && $scope.formEUP.model.proposalSignCircuitConfiguration.length > 0)?$scope.formEUP.model.proposalSignCircuitConfiguration:undefined);
                    	if(newProcedure.proposalSignCircuit){
                    		newProcedure.proposalSignCircuit = angular.copy(newProcedure.proposalSignCircuit);	
                    	}
                    	
                    	newProcedure.secNotification = (($scope.formEUP && $scope.formEUP.model && $scope.formEUP.model.secretaryNotificationConfiguration)?$scope.formEUP.model.secretaryNotificationConfiguration:undefined);
                    	if(newProcedure.secNotification){
                    		newProcedure.secNotification = angular.copy(newProcedure.secNotification);	
                    		if(newProcedure.secNotification.registerOffice && newProcedure.secNotification.registerOffice.id){
                    			newProcedure.secNotification.registerOffice = {id:newProcedure.secNotification.registerOffice.id};
                    		}
                    		if(newProcedure.secNotification.profile && newProcedure.secNotification.profile.id){
                    			newProcedure.secNotification.profile = {id:newProcedure.secNotification.profile.id};
                    		}
                    		if(newProcedure.secNotification.archiveClassification && newProcedure.secNotification.archiveClassification.id){
                    			newProcedure.secNotification.archiveClassification = {id:newProcedure.secNotification.archiveClassification.id};
                    		}
                    		if(newProcedure.secNotification.docs && newProcedure.secNotification.docs.length > 0){
                    			_.forEach(newProcedure.secNotification.docs, function(doc){
                    				if(doc.type && doc.type.id){
                    					doc.type = {id:doc.type.id};
                    				}
                    				if(doc.template && doc.template.id){
                    					doc.template = {id:doc.template.id};
                    				}
                    				if(doc.archiveClassification && doc.archiveClassification.id){
                    					doc.archiveClassification = {id:doc.archiveClassification.id};
                    				}
                    			});
                    		}
                    	}
                    	newProcedure.procedureProposalTypes = (($scope.formEUP && $scope.formEUP.model && $scope.formEUP.model.procedureProposalTypes)?$scope.formEUP.model.procedureProposalTypes:undefined);
                    	var customFields = [];
                    	angular.forEach($scope.customFieldsProcedure,function(value, key) {
                    		var field = {
                    				customField: value.customField,
                    				required: value.requiredString == 'REQUIRED',
                    				requiredForEndDossier: value.requiredString == 'REQUIRED_TO_END',
                    				hiddenField: value.requiredString == 'HIDDEN_FIELD',
                    				noEditable: value.requiredString == 'NO_EDITABLE',
                    				viewOrder: value.viewOrder,
                    				value: value.value,
                    				customFieldType: value.customFieldType,
                    				transactionTypes: value.transactionTypes,
                    				descriptionLanguage1: value.descriptionLanguage1,
        							descriptionLanguage2: value.descriptionLanguage2,
        							descriptionLanguage3: value.descriptionLanguage3,
        							nameLanguage1: value.nameLanguage1,
        							nameLanguage2: value.nameLanguage2,
        							nameLanguage3: value.nameLanguage3,
			                        conditional: value.conditional,
			                        spel: value.spel,
			                        spelDossier: value.spelDossier,
			                        identifierField: value.identifierField,
			                        labelPropField:value.labelPropField,
			                        labelFieldsSeparator:value.labelFieldsSeparator,
			                        parentField:value.parentField,
			                        queryParams: value.queryParams,
			                        relatedCustomFields: value.relatedCustomFields,
			                        calculated:value.calculated,
			                        dynamicallyCalculated: value.dynamicallyCalculated,
			                        spelCalculated: value.spelCalculated,
			                        spelCalculatedDossier: value.spelCalculatedDossier,
			                        canPublish: value.canPublish,
			                        groups: value.groups
                    		};
                    		customFields.push(field);
                    	});
                    	newProcedure.initCfieldWhenSaved = (($scope.initCfieldWhenSaved)?true:false);
                    	var currentLanguage = $translate.use();
                    	var lang1 = Language.getByColumn('language1');
                    	var lang2 = Language.getByColumn('language2');
                    	var lang3 = Language.getByColumn('language3');
                    	var trans = {};
                    	$translate.use(lang1).then(function (translation) {
                    		trans.tranLanguage1 = $translate.instant('global.literals.procedure_transaction_title');
                    		$translate.use(lang2).then(function (translation) {
                    			trans.tranLanguage2 = $translate.instant('global.literals.procedure_transaction_title');
                    			$translate.use(lang3).then(function (translation) {
                    				trans.tranLanguage3 = $translate.instant('global.literals.procedure_transaction_title');
                    				$translate.use(currentLanguage);
                    				CommonAdminService.insertUnguidedProcedure(newProcedure, customFields, trans, $scope.documentationToProvide, $scope.createTransactions)
                    				.then(function(data) {
                    					if(GlobalDataFactory.procedures){
                    						GlobalDataFactory.procedures.length = 0;
                    					}else{
                    						GlobalDataFactory.procedures = [];
                    					}
                    					if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=Procedure&origin=globalData')){
                    						CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=Procedure&origin=globalData')
                    					}
                    					GlobalDataFactory.loadGlobalData();
                    					$state.transitionTo('annexa.admin.unguided_procedure');
                    				}).catch(function (error) {
                    					if(error && error.message) {
                    						$scope.alerts.push({ msg: $filter('translate')(error.message) });
                    					}
                    					$translate.use(currentLanguage);
                    				});
                    			}).catch(function (error) {
                    				$translate.use(currentLanguage);
                    			})
                    		}).catch(function (error) {
                    			$translate.use(currentLanguage);
                    		})
                    	}).catch(function (error) {
                    		$translate.use(currentLanguage);
                    	})
                    }
                }
            }

            $scope.cancel = function() {
                $state.transitionTo('annexa.admin.unguided_procedure');
            };

            $scope.removeClassificationFuntion = function(index){
                if($scope.formEUP.model.procedureClassifications && $scope.formEUP.model.procedureClassifications.length > index){
                    $scope.formEUP.model.procedureClassifications.splice(index, 1);
                }
            };
            $scope.addClassificationFuntion = function(){
                 var id = 0;
                 if(this.optionAdd){
                    var classification = {};
                    classification[$scope.languageColumn] = this.optionAdd;
                    classification.deleted = false;
                    if($scope.formEUP.model.procedureClassifications){
                        $scope.formEUP.model.procedureClassifications.push(classification);
                    }else{
                        $scope.formEUP.model.procedureClassifications = [];
                        $scope.formEUP.model.procedureClassifications.push(classification);
                    }
                    this.optionAdd = '';
                }
            };

            var defaultExpirationDaysNotification = '';
            
            if($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.notification_expiration_days
            		&& $rootScope.app.configuration.notification_expiration_days.value){
            	defaultExpirationDaysNotification = $rootScope.app.configuration.notification_expiration_days.value
            }
            
            $scope.procedure = {showClassification:"DISABLED", showSubject:"DISABLED", classification:{}, allowUserChangeMaxDays: false, active: true, allowUserChangeExpirationDaysNotification: true, allowHaveTerritorialAddress: true, allowManualDossierNumbering: false, expireType:"BUSINESS", forceHaveThird: false, alertTramInternalProfiles: false};
            if(!$scope.procedure.procedureClassifications){
                $scope.procedure.procedureClassifications = [];
            }
            var data = {
                row: true,
                colClass: 'col-xl-12 col-lg-12 col-md-12 col-sm-12',
                labelClass: 'label-strong'
            };
            $scope.formEUP.model = new procedure($scope.procedure, undefined, $scope.isActiveCouncillor);
            if(!$scope.formEUP.model.archiveClassification) {
                $scope.formEUP.model.archiveClassification = {};
            }
            if(!$scope.formEUP.model.family) {
                $scope.formEUP.model.family = {};
            }
			if(!$scope.formEUP.model.councillor) {
                $scope.formEUP.model.councillor = {};
            }
            
            var selectableProfiles = [];
            _.forEach($scope.profilesProcedure, function (value, key) {
        		if(value.expiryDate == null) {
        			selectableProfiles.push(value);
        		}
            });
            
            $scope.formEUP.addField('language1','annexaInputLanguage','',new AnnexaFormlyFieldLanguageTemplateOptions('language','','global.literals.name','horitzontal',true,false,true, $rootScope.app.languagedef),data);
            $scope.formEUP.addField('descriptionLanguage1','annexaInputLanguage','',new AnnexaFormlyFieldLanguageTemplateOptions('descriptionLanguage','descriptionLanguage','global.literals.description','horitzontal',true,false,true,$rootScope.app.languagedef),data);
            $scope.formEUP.addField('family', 'annexaSelectTreeRow','', new AnnexaFormlyFieldSelectTemplateOptions('global.literals.family', 'id', $scope.languageColumn,$scope.familiesProcedureTree,true, undefined, undefined, undefined, true), data);
            $scope.formEUP.addField('archiveClassification','annexaSelectTreeRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.classificationBox','id',$scope.languageColumn,$scope.classificationTree,$rootScope.esetMode, undefined, undefined, undefined, true),data);
            $scope.formEUP.addField('showClassification','annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.commonAdmin.modal.unguidedProcedure.showClassification','id','description',apiAdmin.showClassificationList,true),data);
            $scope.formEUP.addField('procedureClassifications','annexaLanguageFieldSet','',new AnnexaFormlyFieldFieldSetTemplateOptions('global.literals.classification',undefined,'','global.commonAdmin.modal.unguidedProcedure.placeholderClassification',$scope.addClassificationFuntion,$scope.removeClassificationFuntion,'',true, false),data,'model.showClassification == \'DISABLED\'');
            var affto = new AnnexaFormlyFieldTemplateOptions('text','global.literals.acronym',true,false);
            affto.maxlength = 20;
            $scope.formEUP.addField('councillor', 'annexaSelectTreeRow','', new AnnexaFormlyFieldSelectTemplateOptions('global.literals.councillor', 'id', $scope.languageColumn,$scope.councillorsTree,false, undefined, undefined, undefined, true), data);
			var fieldCouncillor = $linq($scope.formEUP.fields).firstOrDefault(undefined,"x => x.key == 'councillor'");
			if(fieldCouncillor && fieldCouncillor.templateOptions){
				fieldCouncillor.templateOptions.onlyLastLevelClick = true	
			}
            $scope.formEUP.addField('active', 'annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.active', 'id', 'description', apiAdmin.yesNoBoolean, true, undefined, undefined, true), data);
            $scope.formEUP.addField('acronym','annexaInputRow','',affto,data);
            $scope.formEUP.addField('maxDays','annexaInputRow','',new AnnexaFormlyFieldTemplateOptions('number','global.literals.expire',true,false),data);
            $scope.formEUP.addField('expireType','annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.commonAdmin.modal.unguidedProcedure.expireType','id','description',apiAdmin.expirationTypeList,true),data);
            $scope.formEUP.addField('expirationDaysNotification','annexaInputRow','',new AnnexaFormlyFieldTemplateOptions('number','global.literals.expirationDaysNotification',true,false),data,'',defaultExpirationDaysNotification);
            $scope.formEUP.addField('allowUserChangeExpirationDaysNotification', 'annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.allowUserChangeExpirationDaysNotification', 'id', 'description', apiAdmin.yesNoBoolean, true, undefined, undefined, true), data);
            $scope.formEUP.addField('allowHaveTerritorialAddress', 'annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.allowHaveTerritorialAddress', 'id', 'description', apiAdmin.yesNoBoolean, true, undefined, undefined, true), data);
            $scope.formEUP.addField('accessLevelNotification','annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.commonAdmin.modal.unguidedProcedure.accessLevelNotification','id','description',apiAdmin.accessLevelNotificationList,false),data);
            $scope.formEUP.addField('daysBeforeAlertDossier','annexaInputRow','',new AnnexaFormlyFieldTemplateOptions('number','global.commonAdmin.modal.unguidedProcedure.daysBeforeAlertDossier',false,false),data);
            $scope.formEUP.addField('daysBeforeAlertTransaction','annexaInputRow','',new AnnexaFormlyFieldTemplateOptions('number','global.commonAdmin.modal.unguidedProcedure.daysBeforeAlertTransaction',false,false),data);
            $scope.formEUP.addField('showSubject','annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.commonAdmin.modal.unguidedProcedure.showSubject','id','description',apiAdmin.showSubjectList,true),data);
            $scope.formEUP.addField('allowUserChangeMaxDays', 'annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.allowUserChangeMaxDays', 'id', 'description', apiAdmin.yesNoBoolean, true, undefined, undefined, true), data);
            $scope.formEUP.addField('transactionTypes','annexaMultipleSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.initialTransactionType','id',Language.getActiveColumn(),$scope.transactionTypes,true),data);
            $scope.formEUP.addField('subprocedures','annexaMultipleSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.guidedSubprocedureCanStart','id',Language.getActiveColumn(),$scope.subproceduresList,false),data);
            $scope.formEUP.addField('tramitationType','annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.tramitationType','id',Language.getActiveColumn(),$scope.tramitationTypes,true),data);
            $scope.formEUP.addField('allowManualDossierNumbering', 'annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.allowManualDossierNumbering', 'id', 'description', apiAdmin.yesNoBoolean, true, undefined, undefined, true), data);
            $scope.formEUP.addField('forceHaveThird', 'annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.forceHaveThird', 'id', 'description', apiAdmin.yesNoBoolean, true, undefined, undefined, true), data);
            var subFielsProfiles = [
                $scope.formEUP.createField('procedureStartProfiles','annexaMultipleSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.initialize','id',Language.getActiveColumn(),selectableProfiles,true, undefined, undefined, undefined, undefined, true),data),
                $scope.formEUP.createField('procedureViewProfiles','annexaMultipleSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.showProcedure','id',Language.getActiveColumn(),selectableProfiles,false, undefined, undefined, undefined, undefined, true),data)
            ];
            if(!$rootScope.esetMode){
                subFielsProfiles.push($scope.formEUP.createField('procedureResponsibleProfiles','annexaMultipleSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.responsible','id',Language.getActiveColumn(),selectableProfiles,false, undefined, undefined, undefined, undefined,true),data));
            }
			subFielsProfiles.push($scope.formEUP.createField('procedureTramitationProfiles','annexaMultipleSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.tramitation','id',Language.getActiveColumn(),selectableProfiles,false, undefined, undefined, undefined, undefined,true),data));
            $scope.formEUP.addFieldGroup('','fileSetWrapper',new AnnexaFormlyFieldFieldSetTemplateOptions('global.literals.profiles',undefined,undefined,undefined,undefined,undefined,undefined,true, false),subFielsProfiles);
            $scope.formEUP.addField('procedureRoleInterested','annexaMultipleSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.roleInterestedProcedure','id',Language.getActiveColumn(),$scope.rolesInterested,false, undefined, undefined, undefined, undefined, true),data)
            $scope.formEUP.addField('informationLanguage1', 'annexaSummernote', '', new AnnexaFormlyFieldSummernoteTemplateOptions('informationLanguage', 'informationLanguage', 'global.literals.information', 'horitzontal', false, false, true, $rootScope.app.languagedef, 3), data);
            if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.operations_invoices_conf && $rootScope.app.configuration.operations_invoices_conf.active){
	            $scope.formEUP.addField('operationsActive','annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.activateOperationModule','id','description',apiAdmin.yesNoBoolean,true),data);
	            var operationTypeHiddenExpression = function($viewValue, $modelValue, scope) {
	            	return ((scope.model.operationsActive)?false:true);
	            }
	            $scope.formEUP.addField('operationTypes','annexaMultipleSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.operationTypes','id',Language.getActiveColumn(),GlobalDataFactory.operationTypes,false, undefined, undefined, undefined, undefined, true),data,operationTypeHiddenExpression);
            }

            
            $scope.formEUP.addField('titleSecretaryConfiguration','annexaLabelRow','p-t',{label:$filter('translate')('global.sec.literals.secretaryConfiguration')},{row: true,colClass: 'col-xl-12 col-lg-12 col-md-12 col-sm-12',labelClass: 'label-strong text _700'});
            $scope.formEUP.addField('proposalType', 'annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.sec.literals.proposalType', 'id', Language.getActiveColumn(), GlobalDataFactory.proposalTypes, false, undefined, undefined, true), data);
            var organs = angular.copy(GlobalDataFactory.organs);
			var opinionOrgans = $linq(organs).where("x => x.resolutionType == 'OPINION'").toArray();
			var agreementOrgans = $linq(organs).where("x => x.resolutionType == 'AGREEMENT'").toArray();
			var delegationTypes = AdminFactory.councillorProposalTypeDelegationType;
			var organsHideExpression = function ($viewValue, $modelValue, scope) {
            	var GlobalDataFactory = angular.element(document.body).injector().get('GlobalDataFactory');
                if(scope.model.proposalType){
                	var pt = $linq(GlobalDataFactory.proposalTypes).firstOrDefault(undefined, "x => x.id == "+scope.model.proposalType);
                	if(pt && pt.proposalTypeSubtype && pt.proposalTypeSubtype == 'PROPOSAL'){
                		return false;
                	}else{
                		return true;
                	}
                }else{
                    return true;
                }
            }
			if($scope.isActiveCouncillor) {
				$scope.formEUP.addField('organsOpinion','annexaComponent','',{type: 'annexa-organs-opinion-councillor-procedure'},{organs: opinionOrgans,delegationTypes: delegationTypes,organsOpinion: []},organsHideExpression),            
	            $scope.formEUP.addField('organsAgreement', 'annexaSelectRow','col-sm-6',new AnnexaFormlyFieldSelectTemplateOptions('global.sec.literals.agreementOrgan', 'id', Language.getActiveColumn(), agreementOrgans, false, undefined, undefined, true), data,organsHideExpression);
				$scope.formEUP.addField('organsAgreementDelegationType', 'annexaSelectRow','col-sm-6',new AnnexaFormlyFieldSelectTemplateOptions('global.commonAdmin.literals.councillorDelegationType', 'id', Language.getActiveColumn(), delegationTypes, false, undefined, undefined, true), data,organsHideExpression);
            }else{
				$scope.formEUP.addField('organsOpinion','annexaMultipleSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.sec.literals.opinionOrgans','id',Language.getActiveColumn(),opinionOrgans,false, undefined, undefined, undefined, undefined, true),data,organsHideExpression),            
	            $scope.formEUP.addField('organsAgreement', 'annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.sec.literals.agreementOrgan', 'id', Language.getActiveColumn(), agreementOrgans, false, undefined, undefined, true), data,organsHideExpression);
			}
            $scope.formEUP.addField('proposalTitle','annexaInputRow','',new AnnexaFormlyFieldTemplateOptions('text','global.sec.literals.proposalTitle',false,false),data);
            $scope.formEUP.addField('footerClaims','annexaMultipleSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.sec.literals.footerClaims','id',Language.getActiveColumn(),GlobalDataFactory.footerClaims,false),data);
            var secProfiles = angular.copy(AdminFactory.profiles);
			secProfiles = ((secProfiles)?$linq(secProfiles).where("x => !x.expiryDate").toArray():[]);
            $scope.formEUP.addField('notificatorProfile', 'annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.sec.literals.notificator_profile', 'id', Language.getActiveColumn(), secProfiles, false, undefined, undefined, true), data);
            $scope.formEUP.addField('internalProfiles','annexaMultipleSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.internalCommunication','id',Language.getActiveColumn(),secProfiles,false),data);
    
            var showAlertInternalProfiles = true;
            if($rootScope.esetMode || ($rootScope.app && $rootScope.app.configuration &&  $rootScope.app.configuration.assign_responsible_user 
            		&& $rootScope.app.configuration.assign_responsible_user.dossierTransaction && $rootScope.app.configuration.assign_responsible_user.dossierTransaction == 'Obligatoria')) {
            	showAlertInternalProfiles = false;
            }
            if(showAlertInternalProfiles) {
            	$scope.formEUP.addField('alertTramInternalProfiles', 'annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.alertTramInternalProfiles', 'id', 'description', apiAdmin.yesNoBoolean, true, undefined, undefined, true), data);
                var ttypeAlert = angular.copy(GlobalDataFactory.transactiontypes);
                ttypeAlert = $linq(ttypeAlert).where("x => x.transactionSubtype == " + apiAdmin.transactionSubtypes[0].id + " || x.transactionSubtype == " + apiAdmin.transactionSubtypes[1].id).toArray();
                $scope.formEUP.addField('transactionInternalProfilesAlert','annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.transactionInternalProfilesAlert','id',Language.getActiveColumn(),ttypeAlert,false),data);
            }

            var ttypeAmmendment = angular.copy(GlobalDataFactory.transactiontypes);
            ttypeAmmendment = $linq(ttypeAmmendment).where("x => x.transactionSubtype == " + apiAdmin.transactionSubtypes[4].id).toArray();
            $scope.formEUP.addField('ammendmentTransactionType','annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.sec.literals.amendmentProposal','id',Language.getActiveColumn(),ttypeAmmendment,false),data);
            var ttypeTransfer = angular.copy(GlobalDataFactory.transactiontypes);
            ttypeTransfer = $linq(ttypeTransfer).where("x => x.transactionSubtype == " + apiAdmin.transactionSubtypes[3].id).toArray();
            $scope.formEUP.addField('transferTransactionType','annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.sec.literals.transferProposal','id',Language.getActiveColumn(),ttypeTransfer,false),data);
            var ttypeCancel = angular.copy(GlobalDataFactory.transactiontypes);
            ttypeCancel = $linq(ttypeCancel).where("x => x.transactionSubtype == " + apiAdmin.transactionSubtypes[0].id + " || x.transactionSubtype == " + apiAdmin.transactionSubtypes[1].id).toArray();
            $scope.formEUP.addField('cancelTransactionType','annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.sec.literals.cancelArchiveProposal','id',Language.getActiveColumn(),ttypeCancel,false),data);
            $scope.formEUP.addField('inspectionIntervention', 'annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.sec.literals.inspectionIntervention', 'id', 'description', apiAdmin.yesNoBooleanNull, false), data);
            var spelInspectionInterventionHiddenExpression = function($viewValue, $modelValue, scope) {
            	if(scope.model.inspectionIntervention) {
                	if(scope.model.inspectionIntervention == 'CONDITIONAL' || (scope.model.inspectionIntervention && scope.model.inspectionIntervention.id && scope.model.inspectionIntervention.id == 'CONDITIONAL')){
                		return false;
                	}else{
                		return true;
                	}
                } else {
                    return true;
                }
            }
            $scope.formEUP.addField('spelInspectionIntervention','annexaInputRow','',new AnnexaFormlyFieldTemplateOptions('text','global.sec.literals.inspectionInterventionSpel',false,false),data,spelInspectionInterventionHiddenExpression);
            var insIntTypeHiddenExpression = function($viewValue, $modelValue, scope) {
            	if($scope.internalControl && scope.model.inspectionIntervention) {
            		return false;
            	} else {
            		return true;
            	}
            }
            $scope.formEUP.addField('inspectionInterventionType','annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.sec.literals.inspectionInterventionType','id',"name",apiAdmin.inspectionInterventionTypes,true),data,insIntTypeHiddenExpression);
            var secNotificationHideExpression = function($viewValue, $modelValue, scope) {
            	if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.sec_notification_configuration && $rootScope.app.configuration.sec_notification_configuration.type == 'PARTIAL'){
                    return false;
                } else {
                    return true;
                }
            }
            var publicTemplates = angular.copy(GlobalDataFactory.publictramtemplates);
            _.forEach(publicTemplates, function (value, key){
            	value.literal = value[Language.getActiveColumn()];
            });
            
            $scope.formEUP.addField('responsibleUserInternalControl','annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.sec.literals.responsibleUserInternalControl','id','value', AdminFactory.usersList,false),data);
            $scope.formEUP.addField('predefinedRecords','annexaInputRow','',new AnnexaFormlyFieldTemplateOptions('text','global.sec.literals.predefinedRecords',false,false),data);
            
            $scope.formEUP.addField('proposalTemplate', 'annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.sec.literals.proposalTemplate', 'id', 'literal', $linq(publicTemplates).orderBy("x => x.literal", linq.caseInsensitiveComparer).toArray(), false, undefined, undefined), data);
            
            $scope.formEUP.addField('proposalSignCircuit', 'annexaLabelButton','',{buttonLabel: 'global.sec.literals.configureProposalSignCircuit',executeFunction: function() {TramNewFactory.configureProposalSignCircuit($scope.formEUP.model, undefined, undefined, true);}}, data);
            $scope.formEUP.addField('secNotification', 'annexaLabelButton','',{buttonLabel: 'global.sec.literals.configureSecNotificacions',executeFunction: function() {TramNewFactory.configureSecNotifications($scope.formEUP.model, undefined, undefined, true);}}, data,secNotificationHideExpression);
            $scope.formEUP.addField('procedureProposalTypes', 'annexaLabelButton','',{buttonLabel: 'global.sec.literals.configureProcedureProposalTypes',executeFunction: function() {TramNewFactory.configureProcedureProposalTypes($scope.formEUP.model, undefined);}}, data);
            
            
            $scope.formEUP.addField('titleArchiveConfiguration','annexaLabelRow','p-t',{label:$filter('translate')('global.archive.toptitleConfiguration')},{row: true,colClass: 'col-xl-12 col-lg-12 col-md-12 col-sm-12',labelClass: 'label-strong text _700'});
            $scope.formEUP.addField('closeExpireType','annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.sec.literals.closeExpireType','id','description',apiAdmin.expirationTypeList,false),data);
            $scope.formEUP.addField('closeExpireQuantity','annexaInputRow','',new AnnexaFormlyFieldTemplateOptions('number','global.sec.literals.closeExpireQuantity',false,false),data);
            $scope.formEUP.addField('automaticClose', 'annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.archive.automaticClose', 'id', 'description', apiAdmin.yesNoBooleanNull, false), data);
            var transferHideExpression = function($viewValue, $modelValue, scope) {
            	if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.close_and_archive && $rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_GEE'){
                    return false;
                } else {
                    return true;
                }
            }
            $scope.formEUP.addField('transferExpireType','annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.sec.literals.transferExpireType','id','description',apiAdmin.expirationTypeList,false),data,transferHideExpression);
            $scope.formEUP.addField('transferExpireQuantity','annexaInputRow','',new AnnexaFormlyFieldTemplateOptions('number','global.sec.literals.transferExpireQuantity',false,false),data,transferHideExpression);
            $scope.formEUP.addField('automaticTransfer', 'annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.archive.automaticTransfer', 'id', 'description', apiAdmin.yesNoBooleanNull, false), data,transferHideExpression);
            var archiveHideExpression = function($viewValue, $modelValue, scope) {
            	if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.close_and_archive && ($rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_TOOL' || $rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_GEE')){
                    return false;
                } else {
                    return true;
                }
            }
            $scope.formEUP.addField('archiveExpireType','annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.sec.literals.archiveExpireType','id','description',apiAdmin.expirationTypeList,false),data,archiveHideExpression);
            $scope.formEUP.addField('archiveExpireQuantity','annexaInputRow','',new AnnexaFormlyFieldTemplateOptions('number','global.sec.literals.archiveExpireQuantity',false,false),data,archiveHideExpression);
            $scope.formEUP.addField('automaticArchive', 'annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.archive.automaticArchive', 'id', 'description', apiAdmin.yesNoBooleanNull, false), data,archiveHideExpression);
        }])
    .controller('EditUnguidedProcedureController',['$scope', '$stateParams', '$timeout', '$rootScope', 'AnnexaFormlyFactory', 'CommonAdminModals', 'HeaderService', '$state', 'TableFilter', 'DTColumnBuilder', '$filter', '$compile', 'RestService', 'Language', 'CommonAdminService', 'apiAdmin', 'HelperService', 'CustomFieldFactory','ErrorFactory', 'AdminFactory', 'globalModals', 'GlobalDataFactory', 'CacheFactory', 'DialogsFactory', 'TramNewFactory',
        function($scope, $stateParams, $timeout, $rootScope, AnnexaFormlyFactory, CommonAdminModals, HeaderService, $state, TableFilter, DTColumnBuilder, $filter, $compile, RestService, Language, CommonAdminService, apiAdmin, HelperService, CustomFieldFactory, ErrorFactory, AdminFactory, globalModals, GlobalDataFactory, CacheFactory, DialogsFactory, TramNewFactory) {

            HeaderService.onChangeState($scope, function(message) {
                if(message.state.name == 'annexa.admin.unguided_procedure.edit_unguided_procedure') {
                    $rootScope.subHeadButtons = [];
                }
            });
            HeaderService.changeState($state.current);
            if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.councillor_proposal_type && $rootScope.app.configuration.councillor_proposal_type.active) {
            	$scope.isActiveCouncillor = true;
            }else{
            	$scope.isActiveCouncillor = false;
            }
			$scope.showSecNotification = (($rootScope.app.configuration && $rootScope.app.configuration.sec_notification_configuration && $rootScope.app.configuration.sec_notification_configuration.type == 'PARTIAL')?true:false);
            $scope.procedure = CustomFieldFactory.procedure;
            $scope.procedureStartProfilesAux = CustomFieldFactory.procedureStartProfilesAux;
            $scope.procedureTramitationProfilesAux = CustomFieldFactory.procedureTramitationProfilesAux;
            $scope.procedureViewProfilesAux =CustomFieldFactory.procedureViewProfilesAux;
            $scope.procedureResponsibleProfilesAux =CustomFieldFactory.procedureResponsibleProfilesAux;
            $scope.procedureRolesInterestedAux = CustomFieldFactory.procedureRolesInterestedAux;
            $scope.customFieldsProcedure = CustomFieldFactory.customFieldsProcedure;
            $scope.customFields = CustomFieldFactory.customFields;
            $scope.classificationBoxs = CustomFieldFactory.classificationBoxs;
            $scope.transactionTypesAux = CustomFieldFactory.transactionTypesAux;
            $scope.createTransactionTypesAux = CustomFieldFactory.procedure.createTransactionTypes;
            $scope.subproceduresAux = CustomFieldFactory.subproceduresAux;
			$scope.documentationToProvide = CustomFieldFactory.documentationToProvide;
            $scope.transactionTypes = [];
            $scope.footerClaims = GlobalDataFactory.footerClaims;
            $scope.footerClaimsAux = CustomFieldFactory.footerClaimsAux;
            var organs = angular.copy(GlobalDataFactory.organs);
            $scope.organsOpinion = $linq(organs).where("x => x.resolutionType == 'OPINION'").toArray();
			$scope.organsAgreement = $linq(organs).where("x => x.resolutionType == 'AGREEMENT'").toArray();
			$scope.organsOpinionAux = [];
			
			$scope.summernoteConfig = {
				      height: 300,
      		        toolbar: [
    		            ['style', ['style']],
    		            ['font', ['bold', 'italic', 'underline']],
    		            ['fontname', ['fontname']],
    		            ['fontsize', ['fontsize']],
    		            ['color', ['color']],
    		            ['para', ['ul', 'ol', 'paragraph']],
    		            ['table', ['table']],
    		            ['insert', ['link', 'picture']],
    		            ['view', ['codeview']],
    		          ],
    		          icons: {
    		        	    'italic': 'fa fa-italic',
    		        	    'bold': 'fa fa-bold',
    		        	    'underline': 'fa fa-underline',
    		        	    'clear': 'fa fa-eraser', 
    		        	    'fontname': 'fa fa-font',
    		        	    'fontsize': 'fa fa-text-height',
    		        	    'font': 'fa fa-font',
    		        	    'unorderedlist': 'fa fa-list-ul',
    		        	    'orderedlist': 'fa fa-list-ol',
    		        	    'alignLeft': 'fa fa-align-left',
    		        	    'table': 'fa fa-table',
    		        	    'link': 'fa fa-link',
    		        	    'picture': 'fa fa-picture-o',
    		        	    'code': 'fa fa-code',
    		        	    'help': 'fa fa-question-circle',
    		        	    'magic': 'fa fa-text-height',
    		        	    'alignJustify': 'fa fa-align-justify',
    		        	    'alignRight': 'fa fa-align-right',
    		        	    'alignCenter': 'fa fa-align-center',
    		        	    'outdent': 'fa fa-outdent',
    		        	    'indent': 'fa fa-indent',

    		          }
			};
			
            $scope.organsOpinionAuxCouncillor = {
            	data:{
            		organs: $scope.organsOpinion,
                    delegationTypes: AdminFactory.councillorProposalTypeDelegationType,
					organsOpinion: [],
					deleteOpinionOrgan: function(args){
	                	if(args && args.index > -1 && $scope.organsOpinionAuxCouncillor.data.organsOpinion && $scope.organsOpinionAuxCouncillor.data.organsOpinion.length > args.index){
	                		var ppoo = $scope.organsOpinionAuxCouncillor.data.organsOpinion[args.index];
	                		if(ppoo && ppoo.organ && ppoo.organ.id){
			            		CommonAdminService.deleteOpinionOrganWithCouncillor($scope.procedure.id, ppoo.organ.id).then(function (data) {
			            			$scope.organsOpinionAuxCouncillor.data.organsOpinion.splice(args.index, 1);
			                    	if(GlobalDataFactory.procedures){
			                        	GlobalDataFactory.procedures.length = 0;
			                        }else{
			                        	GlobalDataFactory.procedures = [];
			                        }
			                        if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=Procedure&origin=globalData')){
			                        	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=Procedure&origin=globalData')
			                        }
			                        GlobalDataFactory.loadGlobalData();
			                    }).catch(function (error) {
			                    });
		                		
	                		}
	                	}
	                },
	                addOpinionOrgan: function(args){
	                	if(args && args.organ && args.delegationType && args.modal){
	                		var ppoo = {organ: args.organ, delegationType:args.delegationType};
		            		CommonAdminService.addOpinionOrganWithCouncillor($scope.procedure.id, ppoo).then(function (data) {
		            			$scope.organsOpinionAuxCouncillor.data.organsOpinion.push(data);
		                    	if(GlobalDataFactory.procedures){
		                        	GlobalDataFactory.procedures.length = 0;
		                        }else{
		                        	GlobalDataFactory.procedures = [];
		                        }
		                        if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=Procedure&origin=globalData')){
		                        	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=Procedure&origin=globalData')
		                        }
		                        GlobalDataFactory.loadGlobalData();
		                        args.modal.close(); 
		                    }).catch(function (error) {
		                    });
	                	}
	                }
	            }
            }
            if($scope.procedure.organsOpinion){
                angular.forEach($scope.procedure.organsOpinion, function(value, key){
                	if($scope.isActiveCouncillor){
                		var oo = angular.copy(value);
                		oo.organ = $linq(GlobalDataFactory.organs).firstOrDefault({id:oo.organ.id}, "x => x.id == "+oo.organ.id);
                		oo.delegationType = $linq(AdminFactory.councillorProposalTypeDelegationType).firstOrDefault({id:oo.delegationType.id}, "x => x.id == "+oo.delegationType.id);
                		$scope.organsOpinionAuxCouncillor.data.organsOpinion.push(oo)
                	}else{
                		$scope.organsOpinionAux.push(value.organ.id)
                	}
                });
            }
            $scope.internalProfilesAux = [];
            if($scope.procedure.internalProfiles){
                angular.forEach($scope.procedure.internalProfiles, function(value, key){
                	$scope.internalProfilesAux.push(value.profile.id)
                });
            }
            $scope.proposalTypes = GlobalDataFactory.proposalTypes;
            $scope.descriptionColumn = "description";
            $scope.showAlertInternalProfiles = true;
            if($rootScope.esetMode || ($rootScope.app && $rootScope.app.configuration &&  $rootScope.app.configuration.assign_responsible_user 
            		&& $rootScope.app.configuration.assign_responsible_user.dossierTransaction && $rootScope.app.configuration.assign_responsible_user.dossierTransaction == 'Obligatoria')) {
            	$scope.showAlertInternalProfiles = false;
            }
            var ttypeAlert = angular.copy(GlobalDataFactory.transactiontypes);
            $scope.transactionTypesAlert = $linq(ttypeAlert).where("x => x.transactionSubtype == " + apiAdmin.transactionSubtypes[0].id + " || x.transactionSubtype == " + apiAdmin.transactionSubtypes[1].id).toArray();
            var ttypeAmmendment =  angular.copy(GlobalDataFactory.transactiontypes);
            $scope.transactionTypesAmmendment =  $linq(ttypeAmmendment).where("x => x.transactionSubtype == " + apiAdmin.transactionSubtypes[4].id).toArray();
            var ttypeTransfer = angular.copy(GlobalDataFactory.transactiontypes);
            $scope.transactionTypesTransfer = $linq(ttypeTransfer).where("x => x.transactionSubtype == " + apiAdmin.transactionSubtypes[3].id).toArray();
            var ttypeCancel = angular.copy(GlobalDataFactory.transactiontypes);
            $scope.transactionTypesCancel = $linq(ttypeCancel).where("x => x.transactionSubtype == " + apiAdmin.transactionSubtypes[0].id + " || x.transactionSubtype == " + apiAdmin.transactionSubtypes[1].id).toArray();
            $scope.publicTemplates = angular.copy(GlobalDataFactory.publictramtemplates);
            $scope.publicTemplates.unshift({id: -1, language1: '', language2: '', language3: ''});
            TramNewFactory.getTramTemplates(-1, (($scope.procedure && $scope.procedure.id)?$scope.procedure.id:-1)).then(function (data) {
            	$scope.publicTemplates = angular.copy($linq(TramNewFactory.TramTemplates).orderBy("x => x." + $scope.languageColumn, linq.caseInsensitiveComparer).toArray());
                $scope.publicTemplates.unshift({id: -1, language1: '', language2: '', language3: ''});
			});
            $scope.delegationTypes = AdminFactory.councillorProposalTypeDelegationType;
			if(GlobalDataFactory.transactiontypes && GlobalDataFactory.transactiontypes.length > 0){
                $scope.transactionTypes = $linq(GlobalDataFactory.transactiontypes).where("x => x.startType == true && x.active == 1").toArray();
            }
            $scope.subproceduresList = [];
            if(GlobalDataFactory.procedures && GlobalDataFactory.procedures.length > 0) {
            	$scope.subproceduresList = $linq(GlobalDataFactory.procedures).where("x => x.procedureType == 'SUBPROCEDURE' || x.procedureType == 'PROCEDURE_SUBPROCEDURE'").toArray();
            }
            $scope.usersList = angular.copy(AdminFactory.usersList);
            if($scope.usersList && $scope.usersList.length > 0) {
            	$scope.usersList = $linq($scope.usersList).select("x => x.user").toArray();
            	$scope.usersList.unshift({id: -1, completeName: ''});
            }
            $scope.tramitationTypes = GlobalDataFactory.dossierTramitationTypes;
            $scope.operationTypesAux = (($scope.procedure && $scope.procedure.operationTypes)?$linq($scope.procedure.operationTypes).where("x => x.operationType && x.operationType.id").select("x => x.operationType.id").toArray():[]);
            $scope.operationTypes = GlobalDataFactory.operationTypes;
            $scope.cancel = function() {
                $state.transitionTo('annexa.admin.unguided_procedure');
            };
            $scope.operationsActive = (($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.operations_invoices_conf && $rootScope.app.configuration.operations_invoices_conf.active)?true:false);
            $scope.removeClassificationFuntion = function(index){
                if($scope.procedure.procedureClassifications && $scope.procedure.procedureClassifications.length > index){
                    $scope.procedure.procedureClassifications[index].deleted = true;
                    $scope.procedure.procedureClassifications[index].removedDate = new Date();
                    $scope.procedure.procedureClassifications[index].procedure = {id : $scope.procedure.id};
                    CommonAdminService.insertOrUpdateProcedureClassification($scope.procedure.procedureClassifications[index]).then(function(data) {
                        $scope.procedure.procedureClassifications.splice(index, 1);
                        if(GlobalDataFactory.procedures){
                        	GlobalDataFactory.procedures.length = 0;
                        }else{
                        	GlobalDataFactory.procedures = [];
                        }
                        if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=Procedure&origin=globalData')){
                        	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=Procedure&origin=globalData')
                        }
                        GlobalDataFactory.loadGlobalData();
                    }).catch(function (error) {
                        $scope.procedure.procedureClassifications[index].deleted = false;
                        $scope.procedure.procedureClassifications[index].removedDate = null;
                    });
                }
            };

            $scope.addClassificationFuntion = function(){
                var id = 0;
                if(this.optionAdd){
                    var classification = {};
                    classification.language1 = this.optionAdd;
                    classification.language2 = this.optionAdd;
                    classification.language3 = this.optionAdd;
                    classification.procedure = $scope.procedure;
                    CommonAdminService.insertOrUpdateProcedureClassification(classification).then(function(data) {
                        if($scope.procedure.procedureClassifications){
                            $scope.procedure.procedureClassifications.push(JSOG.decode(data));
                        }else{
                            $scope.procedure.procedureClassifications = [];
                            $scope.procedure.procedureClassifications.push(JSOG.decode(data));
                        }
                        if(GlobalDataFactory.procedures){
                        	GlobalDataFactory.procedures.length = 0;
                        }else{
                        	GlobalDataFactory.procedures = [];
                        }
                        if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=Procedure&origin=globalData')){
                        	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=Procedure&origin=globalData')
                        }
                        GlobalDataFactory.loadGlobalData();
                    }).catch(function (error) {
                        var a =0;
                    });
                    this.optionAdd = '';
                }
            };
			$scope.configureProposalSignCircuit = function(){
				TramNewFactory.configureProposalSignCircuit(undefined, 'Procedure', $scope.procedure.id, true);
			}
			$scope.configureSecNotificacions = function(){
				TramNewFactory.configureSecNotifications(undefined, 'Procedure', $scope.procedure.id, true);
			}
			$scope.configureProcedureProposalTypes = function(){
				TramNewFactory.configureProcedureProposalTypes(undefined, $scope.procedure.id);
			}
            $scope.updateClassificationFuntion = function(index){
                if($scope.procedure.procedureClassifications[index]){
                	$scope.procedure.procedureClassifications[index].procedure = {id : $scope.procedure.id};
                    CommonAdminService.insertOrUpdateProcedureClassification($scope.procedure.procedureClassifications[index]).then(function(data) {
                        $scope.procedure.procedureClassifications[index] = JSOG.decode(data);
                        if(GlobalDataFactory.procedures){
                        	GlobalDataFactory.procedures.length = 0;
                        }else{
                        	GlobalDataFactory.procedures = [];
                        }
                        if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=Procedure&origin=globalData')){
                        	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=Procedure&origin=globalData')
                        }
                        GlobalDataFactory.loadGlobalData();
                    }).catch(function (error) {
                        var b = 0;
                    });
                }
            };

            $scope.getProfileValue = function(profiles){
                var prof = '';
                if(profiles){
                    angular.forEach(profiles, function(value, key){
                        var label = '';
                        angular.forEach($scope.profilesProcedure, function(value2, key2){
                            if(value == value2.id) {
                                label = value2[$scope.languageColumn];
                            }
                        });
                        if(prof){
                            prof += ", "+label;
                        }else{
                            prof += label;
                        }
                    })
                }
                return prof;
            };
            
            $scope.getRolesInterestedValue = function(roles){
                var prof = '';
                if(roles){
                    angular.forEach(roles, function(value, key){
                        var label = '';
                        angular.forEach($scope.rolesInterested, function(value2, key2){
                            if(value == value2.id) {
                                label = value2[$scope.languageColumn];
                            }
                        });
                        if(prof){
                            prof += ", "+label;
                        }else{
                            prof += label;
                        }
                    })
                }
                return prof;
            };

            $scope.getTransactionTypeValue = function(transactionTypes){
                var prof = '';
                if(transactionTypes){
                    angular.forEach(transactionTypes, function(value, key){
                        var label = '';
                        angular.forEach($scope.transactionTypes, function(value2, key2){
                            if(value == value2.id) {
                                label = value2[$scope.languageColumn];
                            }
                        });
                        if(prof){
                            prof += ", "+label;
                        }else{
                            prof += label;
                        }
                    })
                }
                return prof;
            };
            
            $scope.getSubprocedureValue = function(subprocedures){
                var prof = '';
                if(subprocedures){
                    angular.forEach(subprocedures, function(value, key){
                        var label = '';
                        angular.forEach($scope.subproceduresList, function(value2, key2){
                            if(value == value2.id) {
                                label = value2[$scope.languageColumn];
                            }
                        });
                        if(prof){
                            prof += ", "+label;
                        }else{
                            prof += label;
                        }
                    })
                }
                return prof;
            };
            
            $scope.getFooterClaimValue = function(footerClaims){
                var prof = '';
                if(footerClaims){
                    angular.forEach(footerClaims, function(value, key){
                        var label = '';
                        angular.forEach($scope.footerClaims, function(value2, key2){
                            if(value == value2.id) {
                                label = value2[$scope.languageColumn];
                            }
                        });
                        if(prof){
                            prof += ", "+label;
                        }else{
                            prof += label;
                        }
                    })
                }
                return prof;
            };
    
            $scope.getOrgansOpinionValue = function(opinionOrgans){
                var prof = '';
                if(opinionOrgans){
                    angular.forEach(opinionOrgans, function(value, key){
                        var label = '';
                        var oo = $linq($scope.organsOpinion).firstOrDefault(undefined, "x => x.id == "+value);
                        if(oo){
                            label = oo[$scope.languageColumn];
                            if(prof){
                        		prof += ", "+label;
                        	}else{
                        		prof += label;
                        	}
                        }
                    })
                }
                return prof;
            };
            
            $scope.getInternalProfilesValue = function(internalProfiles){
                var prof = '';
                if(internalProfiles){
                    angular.forEach(internalProfiles, function(value, key){
                        var label = '';
                        var oo = $linq($scope.profilesProcedure).firstOrDefault(undefined, "x => x.id == "+value);
                        if(oo){
                            label = oo[$scope.languageColumn];
                            if(prof){
                        		prof += ", "+label;
                        	}else{
                        		prof += label;
                        	}
                        }
                    })
                }
                return prof;
            };
            $scope.openModalSelectMultiple = function(label, property, valuesAux) {
            	var modal = angular.copy(globalModals.selectMultipleCheckbox);
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {};
                modal.annexaFormly.options = {};
                modal.annexaFormly.model.modal_body.selectValues = {};
                modal.title = label;
                modal.property = property;
                
                var selectableOptions = [];
                if(property == 'procedureRoleInterested') {
                	_.forEach(this.rolesInterested, function (value, key) {
                    	selectableOptions.push({ id: value.id, value: value.language1});
                    	modal.annexaFormly.model.modal_body.selectValues[value.id] = false;
                    });
                } else {
                	_.forEach(this.profilesProcedure, function (value, key) {
                		if(value.expiryDate == null || $scope[valuesAux].includes(value.id)) {
                			selectableOptions.push({ id: value.id, value: value.language1});
                        	modal.annexaFormly.model.modal_body.selectValues[value.id] = false;
                		}
                    });
                }
                _.forEach($scope[valuesAux], function (value, key) {
                    modal.annexaFormly.model.modal_body.selectValues[value] = true;
                });
                
                modal.annexaFormly.fields[0].fieldGroup[1].templateOptions.options = selectableOptions;
                
                var submitFunction = function () {
                	var selectedOptions = [];
                	var selectOptions = modal.annexaFormly.fields[0].fieldGroup[1].templateOptions.options;
                	if(modal.annexaFormly.model.modal_body.selectValues){
                    	var keys = Object.keys(modal.annexaFormly.model.modal_body.selectValues);
                        if(keys && keys.length > 0){
                            _.forEach(keys, function(key){
                                if(modal.annexaFormly.model.modal_body.selectValues[key]){
                                	var sel = $linq(selectOptions).firstOrDefault(function (x) { return x.id == parseInt(key); });
                                	if(sel != null) {
                                		selectedOptions.push(sel.id);
                                	}
                                }
                            });
                        }
                    }
                	$scope.updateProcedure(selectedOptions, modal.property);
                	$scope[valuesAux] = selectedOptions;
                	this.close();
                }
            	AnnexaFormlyFactory.showModal("selectMultipleCheckbox", modal, submitFunction, false);
            }

            $scope.getOperationTypeValue = function(operationTypes){
                var prof = '';
                if(operationTypes){
                    angular.forEach(operationTypes, function(value, key){
                        var label = '';
                        angular.forEach($scope.operationTypes, function(value2, key2){
                            if(value == value2.id) {
                                label = value2[$scope.languageColumn];
                            }
                        });
                        if(prof){
                            prof += ", "+label;
                        }else{
                            prof += label;
                        }
                    })
                }
                return prof;
            };
            
            $scope.updateProcedure = function(data, property, modal){
                var languages = $rootScope.app.languagedef;
                var model = angular.copy($scope.procedure);
                if(property == 'acronym') {
                	var oldAcronym = $scope.procedure.acronym;
                }
                if(property != 'language' && property != 'descriptionLanguage' && property != 'informationLanguage' && property != 'transactionTypes' 
                	&& property != 'procedureViewProfiles' && property != 'procedureStartProfiles' && property != 'procedureResponsibleProfiles' && property != 'procedureTramitationProfiles' 
                	&& property != 'archiveClassification' && property != 'family' && property != 'councillor' && property != 'operationTypes' && property != 'inspectionIntervention' 
                	&& property != 'inspectionInterventionType'
                	&& property != 'closeExpireType' && property != 'transferExpireType' && property != 'archiveExpireType' && property != 'closeExpireQuantity' 
            		&& property != 'transferExpireQuantity' && property != 'archiveExpireQuantity' && property != 'automaticClose' && property != 'automaticTransfer' 
                	&& property != 'automaticArchive' && property != 'procedureRoleInterested' && property != 'footerClaims' && property != 'subprocedures'  
                	&& property != 'organsOpinion' && property != 'internalProfiles'){
                    if(property == 'maxDays' || property == 'expirationDaysNotification'){
                        if(data) {
                            model[property] = data;
                        }else{
                            return $filter('translate')('global.validation.required');
                        }
                    }else if(property == 'acronym'){
                    	if(data && data != '') {
                            model[property] = data;
                        }else{
                            return $filter('translate')('global.validation.required');
                        }
                    }else if(property == 'predefinedRecords'){
                    	if(data && data != '') {
                            model[property] = data;
                        }else{
                        	model[property] = undefined;
                        }
                    }else{
                        if(data.id && data.id == -1) {
                        	model[property] = undefined;
                        } else {
                        	model[property] = data;
                        }
                    }
                }else if(property == 'procedureViewProfiles' || property == 'procedureStartProfiles' || property == 'procedureResponsibleProfiles' || property == 'procedureTramitationProfiles' || property == 'internalProfiles') {
                    model[property] = procedureListObject(data, true, 'profile');
                }else if(property == 'procedureRoleInterested'){
                    model[property] = procedureListObject(data, true, 'roleInterested');
                }else if(property == 'transactionTypes'){
                    model[property] = procedureListObject(data, true, 'transactionType');
                }else if(property == 'subprocedures'){
                    model[property] = procedureListObject(data, true, 'subprocedure');
                }else if(property == 'footerClaims'){
                    model[property] = procedureListObject(data, true, 'footerClaim');
                }else if(property == 'operationTypes'){
                    model[property] = procedureListObject(data, true, 'operationType');
                }else if(property == 'organsOpinion'){
                    model[property] = procedureListObject(data, true, 'organ');
                } else if(property == 'language' || property == 'descriptionLanguage'){
                    if(languages){
                        var valid = true;
                        angular.forEach(languages, function (value, key) {
                           if (!model[property + value.column.slice(-1)]) {
                                valid = false;
                            }
                        });
                    }
                    if(!valid){
                        return $filter('translate')('global.validation.required');
                    }
                }else if(property == 'informationLanguage'){
                    if(languages){
                        var cont = 1;
                        var valid = false;
                        var lastLanguage = 0;
                        angular.forEach(languages, function (value, key) {
                            if (cont == 1) {
                                valid = true;
                            }else if(!model[property + lastLanguage] && !model[property + value.column.slice(-1)] && valid){
                                valid = true
                            }else if(model[property + lastLanguage] && model[property + value.column.slice(-1)] && valid){
                                valid = true
                            }else{
                                valid = false;
                            }
                            lastLanguage = value.column.slice(-1);
                            cont++;
                        });
                        if(!valid){
                            return $filter('translate')('global.validation.required');
                        }
                    }
                }else if(property == 'archiveClassification'){
                    if(data.$selected && data.$selected.id){
                        model[property] = {id:data.$selected.id};
                    }else{
                        model[property] = {};
                    }
                } else if(property == 'family' || property === 'councillor') {
                    if(data.$selected && data.$selected.id) {
                        model[property] = { id: data.$selected.id };
                    } else {
                        model[property] = {};
                    }
                } else if(property == 'inspectionIntervention') {
                     model[property] = data;
                     if(!data){
                    	 model.inspectionInterventionType = undefined;
                    	 model.inspectionInterventionSpel = undefined;
                     }else if(data !== 'CONDITIONAL'){
                    	 model.inspectionInterventionSpel = undefined;
                     }
                } else if(property == 'inspectionInterventionType') {
                    model[property] = data;
                }else if(property == 'closeExpireType' || property == 'transferExpireType' || property == 'archiveExpireType'){
            		if(data && data.id){
            			model[property] = data.id;
            		}else{
            			model[property] = undefined;
            		}
                }else if(property == 'closeExpireQuantity' || property == 'transferExpireQuantity' || property == 'archiveExpireQuantity'){
                	model[property] = data;
                }else if(property == 'automaticClose' || property == 'automaticTransfer' || property == 'automaticArchive'){
                	model[property] = ((data && data.id != undefined)?data.id:undefined);
                }
                if(model.archiveClassification && model.archiveClassification.model){
                    if(model.archiveClassification.model.$selected && model.archiveClassification.model.$selected.id){
                        model.archiveClassification = {id:model.archiveClassification.model.$selected.id};
                    }else{
                        model.archiveClassification = undefined;
                    }
                }
                if(model.family && model.family.model) {
                    if(model.family.model.$selected && model.family.model.$selected.id) {
                        model.family = { id: model.family.model.$selected.id };
                    } else {
                        model.family = undefined;
                    }
                }
				if(model.councillor && model.councillor.model) {
                    if(model.councillor.model.$selected && model.councillor.model.$selected.id) {
                        model.councillor = { id: model.councillor.model.$selected.id };
                    } else {
                        model.councillor = undefined;
                    }
                }
                CommonAdminService.updateUnguidedProcedure(model).then(function(data) {
                    var proc = JSOG.decode(data);
                    if(property == 'procedureViewProfiles'){
                        $scope.procedure.procedureViewProfiles = data.procedureViewProfiles;
                    }else if(property == 'procedureStartProfiles'){
                        $scope.procedure.procedureStartProfiles = data.procedureStartProfiles;
                    }else if(property == 'procedureTramitationProfiles'){
                        $scope.procedure.procedureTramitationProfiles = data.procedureTramitationProfiles;
                    }else if(property == 'procedureResponsibleProfiles'){
                        $scope.procedure.procedureResponsibleProfiles = data.procedureResponsibleProfiles;
                    }else if(property == 'procedureRoleInterested'){
                        $scope.procedure.procedureRoleInterested = data.procedureRoleInterested;
                    }else if(property == 'footerClaims'){
                        $scope.procedure.footerClaims = data.footerClaims;
                    }else if(property == 'internalProfiles'){
                        $scope.procedure.internalProfiles = data.internalProfiles;
                    }else if(property == 'organsOpinion'){
                        $scope.procedure.organsOpinion = data.organsOpinion;
                    }else if(property == 'responsibleUserInternalControl'){
                        $scope.procedure.responsibleUserInternalControl = data.responsibleUserInternalControl;
                    }else if(property == 'predefinedRecords'){
                    	$scope.procedure.predefinedRecords = data.predefinedRecords;
                    }
                    if(GlobalDataFactory.procedures){
                    	GlobalDataFactory.procedures.length = 0;
                    }else{
                    	GlobalDataFactory.procedures = [];
                    }
                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=Procedure&origin=globalData')){
                    	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=Procedure&origin=globalData')
                    }
                    GlobalDataFactory.loadGlobalData();
                    if(modal && modal.close){
						$scope.procedure[property] = data[property];
						modal.close();
					}
                }).catch(function (error) {
                    if(modal){
						modal.alerts.push({ msg: $filter('translate')('global.documents.EMGDE.errorUpdateEMGDE') });
					} else {
						if(oldAcronym) {
							$scope.procedure.acronym = oldAcronym;
						}
						DialogsFactory.error($filter('translate')(error.message), $filter('translate')('DIALOGS_ERROR_MSG'));
					}
                });
            }

            $scope.updateCustomField = function(customField){
                 CommonAdminService.insertOrUpdateProcedureCustomField(customField).then(function (data) {
                    var a = 0;
                    if(GlobalDataFactory.procedures){
                    	GlobalDataFactory.procedures.length = 0;
                    }else{
                    	GlobalDataFactory.procedures = [];
                    }
                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=Procedure&origin=globalData')){
                    	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=Procedure&origin=globalData')
                    }
                    GlobalDataFactory.loadGlobalData();
                }).catch(function (error) {
                    var b = 0;
                });
            }

            $scope.createModalCustomField = function(procedureCustomField){
                var disabled = procedureCustomField.customField.disableElement;
               CommonAdminService.insertOrUpdateProcedureCustomField(procedureCustomField).then(function (data) {
                    procedureCustomField = JSOG.decode(data);
                    procedureCustomField.requiredString = "OPTIONAL";
                    procedureCustomField.customField.disableElement = disabled;
                    $scope.customFieldsProcedure.push(procedureCustomField);
                    $scope.procedure.customFields.push(procedureCustomField);
                    if(GlobalDataFactory.procedures){
                    	GlobalDataFactory.procedures.length = 0;
                    }else{
                    	GlobalDataFactory.procedures = [];
                    }
                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=Procedure&origin=globalData')){
                    	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=Procedure&origin=globalData')
                    }
                    GlobalDataFactory.loadGlobalData();
               }).catch(function (error) {
                    var b = 0;
               });

            };
            $scope.removeCustomFieldsFuntion = function(customField){
                var aux = HelperService.findFirstElementWhere($scope.procedure.customFields, 'id', customField.id, -1);
                if(aux != -1){
                    aux.deleted = true;
                    aux.removedDate = new Date();
                    aux.required = customField.requiredString == 'REQUIRED';
                    aux.requiredForEndDossier = customField.requiredString == 'REQUIRED_TO_END';
                    aux.hiddenField =  customField.requiredString == 'HIDDEN_FIELD';
                    aux.noEditable = customField.requiredString == 'NO_EDITABLE';
                    aux.viewOrder = customField.viewOrder;
                    aux.value = customField.value;
                    CommonAdminService.insertOrUpdateProcedureCustomField(aux).then(function (data) {
                    	if(GlobalDataFactory.procedures){
                        	GlobalDataFactory.procedures.length = 0;
                        }else{
                        	GlobalDataFactory.procedures = [];
                        }
                        if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=Procedure&origin=globalData')){
                        	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=Procedure&origin=globalData')
                        }
                        GlobalDataFactory.loadGlobalData();
                    }).catch(function (error) {
                        var b = 0;
                    });
                }
            };
            $scope.archiveTool = (($rootScope.app.configuration && $rootScope.app.configuration.close_and_archive && $rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_TOOL')?true:false);
            $scope.archiveGee = (($rootScope.app.configuration && $rootScope.app.configuration.close_and_archive && $rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_GEE')?true:false);

            $scope.openModalUpdateDelegationType = function() {
                var modal = {
                	title: 'global.literals.basicInfo',
                	size: 'modal-md',
                	icon: '',
                	submitModal: function () {},
                	alerts: [],
                	annexaFormly:{
                		
                		fields:[
                			{
                				key: 'organsAgreement',
                				type: 'annexaSelectRow',
                				className: 'col-sm-12',
                				data: {
                					informed:true,
                					row:true,
                					clear: function($event,model,key, $select) {
                						$event.stopPropagation();
                						model[key] = undefined;
                						if($select) {
                							$select.selected = undefined;
                							$select.search = undefined;
                						}
                					}
                				},
                				templateOptions: {
                					optionsAttr: 'bs-options',
                					ngOptions: 'option in to.options | filter: $select.search',
                					label: 'global.sec.literals.agreementOrgan',
                					valueProp: 'id',
                					labelProp: 'language1',
                					placeholder: '',
                					options: [],
                					required: false,
                					focus: false
                				},
                				controller:['$scope', 'Language', 'GlobalDataFactory', function($scope, Language, GlobalDataFactory) {
                					$scope.to.labelProp = Language.getActiveColumn();
                					$scope.to.options = angular.copy(GlobalDataFactory.organs);
                					$scope.to.options = $linq($scope.to.options).where("x => x.resolutionType == 'AGREEMENT'").toArray();
                				}],
                			},
                			{
                				key: 'organsAgreementDelegationType',
                				type: 'annexaSelectRow',
                				className: 'col-sm-12',
                				data: {
                					informed:true,
                					row:true,
                					clear: function($event,model,key, $select) {
                						$event.stopPropagation();
                						model[key] = undefined;
                						if($select) {
                							$select.selected = undefined;
                							$select.search = undefined;
                						}
                					}
                				},
                				templateOptions: {
                					optionsAttr: 'bs-options',
                					ngOptions: 'option in to.options | filter: $select.search',
                					label: 'global.commonAdmin.literals.councillorDelegationType',
                					valueProp: 'id',
                					labelProp: 'language1',
                					placeholder: '',
                					options: [],
                					required: false,
                					focus: false
                				},
                				controller:['$scope', 'Language', 'AdminFactory', function($scope, Language, AdminFactory) {
                					$scope.to.labelProp = Language.getActiveColumn();
                					$scope.to.options = angular.copy(AdminFactory.councillorProposalTypeDelegationType);
                				}]
                			}
                		]
                	}
                }
                
                var saveModalUpdateDelegationType = function(data){
                	 var procedureEdited = angular.copy($scope.procedure);
                	 procedureEdited.organsAgreement = data.annexaFormly.model.organsAgreement;
                	 procedureEdited.organsAgreementDelegationType = data.annexaFormly.model.organsAgreementDelegationType;
                	 if((procedureEdited.organsAgreement && procedureEdited.organsAgreement.id && procedureEdited.organsAgreementDelegationType && procedureEdited.organsAgreementDelegationType.id) || (!procedureEdited.organsAgreement && !procedureEdited.organsAgreementDelegationType)){
                		 var languages = $rootScope.app.languagedef;
                		 if(procedureEdited.archiveClassification && procedureEdited.archiveClassification.model){
                			 if(procedureEdited.archiveClassification.model.$selected && procedureEdited.archiveClassification.model.$selected.id){
                				 procedureEdited.archiveClassification = {id:procedureEdited.archiveClassification.model.$selected.id};
                			 }else{
                				 procedureEdited.archiveClassification = undefined;
                			 }
                		 }
                		 if(procedureEdited.family && procedureEdited.family.model) {
                			 if(procedureEdited.family.model.$selected && procedureEdited.family.model.$selected.id) {
                				 procedureEdited.family = { id: procedureEdited.family.model.$selected.id };
                			 } else {
                				 procedureEdited.family = undefined;
                			 }
                		 }
                		 if(procedureEdited.councillor && procedureEdited.councillor.model) {
                			 if(procedureEdited.councillor.model.$selected && procedureEdited.councillor.model.$selected.id) {
                				 procedureEdited.councillor = { id: procedureEdited.councillor.model.$selected.id };
                			 } else {
                				 procedureEdited.councillor = undefined;
                			 }
                		 }
                		 CommonAdminService.updateUnguidedProcedure(procedureEdited).then(function(dataUpdate) {
                			 if(GlobalDataFactory.procedures){
                				 GlobalDataFactory.procedures.length = 0;
                			 }else{
                				 GlobalDataFactory.procedures = [];
                			 }
                			 if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=Procedure&origin=globalData')){
                				 CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=Procedure&origin=globalData')
                			 }
                			 GlobalDataFactory.loadGlobalData();
                			 $scope.procedure.organsAgreement = dataUpdate.organsAgreement;
                			 $scope.procedure.organsAgreementDelegationType = dataUpdate.organsAgreementDelegationType;
                			 modal.close();
                		 }).catch(function (error) {
                			 modal.alerts.push({ msg: $filter('translate')(error.message) });
                		 });
                	 }else{
                		 modal.alerts.push({ msg: $filter('translate')('global.sec.literals.organWithoutDelegationType') });
                	 }
                }
                modal.annexaFormly.model = {
                	organsAgreement: $scope.procedure.organsAgreement, 
                	organsAgreementDelegationType: $scope.procedure.organsAgreementDelegationType
                };
                
                AnnexaFormlyFactory.showModalPromise("openModalUpdateDelegationType", modal, saveModalUpdateDelegationType, false, false, 'global.literals.save').then(function (modalAux) {
                    modal.modal = modalAux;
                    modal.close = modalAux.element.scope().close;
                    modalAux.element.modal();
                    angular.element(".modal-title").focus();
                }).catch(function () {
                    var a = 0;
                });
            }
            
        }]);