angular
    .module('annexaApp')
    .controller('JobSendSessionAvaController',['$scope', '$rootScope', 'HeaderService', '$state', 'TableFilter', 'DTColumnBuilder', '$filter', '$compile', 'Language', 'CommonAdminService', 'RestService', 'CommonAdminModals', 'AnnexaFormlyFactory',
        function($scope, $rootScope, HeaderService, $state, TableFilter, DTColumnBuilder, $filter, $compile, Language, CommonAdminService, RestService, CommonAdminModals, AnnexaFormlyFactory) {

        $scope.showLoadingdiv = false;
        $scope.languageColumn = Language.getActiveColumn();
        $scope.tfilter = new TableFilter();
        $scope.tfilter.addElement('sessionNumber', 'text', 1, 'global.sec.literals.sessionNumber').setFilterCallAux(true);

        $scope.dtDefJobSendSessionAva = new TableData('jobs', 'JobSendSessionAva', './api/general/filterdata').setSort([[1, 'asc']]).setSortName('id');

        var dateColumn = new DateColumn($filter, 'global.literals.createdDate');
        var lastUpdateColumn = new DateTimeColumn($filter, 'global.sec.literals.lastUpdate');
        
        var renderErrorCounter = function(data, type, full, meta){
        	var content = 0;
        	if(data){
                if(data <= 10) {
                	content = 11 - data;
                }
            }
            return content;
        }
        var renderStatus = function(data, type, full, meta){
        	var content = '';
        	if(data){
        		if(data == 'ERROR' && full.errorMessage) {
        			var tooltip = full.errorMessage.substring(0,500) + '....';
        			
        			content += '<b tooltip-class="customClass" uib-tooltip="' + tooltip + '" tooltip-placement="top" aria-label="' + tooltip + '"">';
        			content += $filter('translate')('global.commonAdmin.literals.' + data);
        			content += '</b>';
        		} else {
            		content = $filter('translate')('global.commonAdmin.literals.' + data)
        		}
            }
            return content;
        }
        var JobSendSessionAvaActionsColumn = new ActionsColumn($filter('translate')('global.literals.actions'),
        		new ActionButton('global.literals.see','viewElement([data])','fa-eye'),
                [new ActionButton('global.literals.retry', 'retry([data])', 'fa-play')]);

        $scope.tableJobSendSessionAva = {
            dtColumns: [
                DTColumnBuilder.newColumn('id').withTitle("Id").withOption('width', '6%'),
                DTColumnBuilder.newColumn('createdDate').withTitle(dateColumn.getTitle()).renderWith(dateColumn.getRender),
                DTColumnBuilder.newColumn('session.id').withTitle($filter('translate')('global.sec.literals.session')),
                DTColumnBuilder.newColumn('session.sessionNumber').withTitle($filter('translate')('global.sec.literals.sessionNumber')),
                DTColumnBuilder.newColumn('status').withTitle($filter('translate')('global.commonAdmin.literals.statusTitle')).renderWith(renderStatus),
                DTColumnBuilder.newColumn('updateStateDate').withTitle(lastUpdateColumn.getTitle()).renderWith(lastUpdateColumn.getRender),
                DTColumnBuilder.newColumn('attempts').withTitle($filter('translate')('global.commonAdmin.literals.errorCountTitle')).renderWith(renderErrorCounter),
                DTColumnBuilder.newColumn('id').withOption('className', 'text-center').withOption('width', '90px').withTitle(JobSendSessionAvaActionsColumn.getTitle()).renderWith(JobSendSessionAvaActionsColumn.getRender).notSortable()
            ],
            filterCall: $scope.tfilter.getFilterCall(),
            filterCallAux: {loggedUser: $rootScope.LoggedUser.id, langColumn: $scope.languageColumn, status: ["ERROR", "PENDING"]},
            reloadInternalData: function (resetPaging) {
                this.filterCall = $scope.tfilter.getFilterCall();
                this.filterCallAux = $scope.tfilter.getFilterCallAux();
                this.filterCallAux.loggedUser = $rootScope.LoggedUser.id;
                this.filterCallAux.langColumn = $scope.languageColumn;
                this.filterCallAux.status = ["ERROR", "PENDING"];
                this.table.reloadData(function callbackInstance(json) {}, resetPaging);
            }
        };
        
        $scope.doSearchFilter=function(queued) {
            $scope.tableJobSendSessionAva.reloadInternalData(true);
        }

        HeaderService.onChangeState($scope, function (message) {
            if(message.state.name == 'annexa.admin.jobSendSessionAva') {
                $rootScope.subHeadButtons = [
                	new HeadButtonSearch("#tableFilter"),
                    new HeadButton('btn primary breadDivButton mr-sm-pt mb-md-0 mb-sm-pt',undefined,"#tableJobSendSessionAva",'fa fa-play','global.literals.retryAll',undefined,'retryAll'),
                ];
            }
        });

        HeaderService.changeState($state.current);

        $scope.retry = function(id) {
        	CommonAdminService.retryJobSendSessionAva(id)
            	.then(function (data) {
            		$scope.tableJobSendSessionAva.reloadInternalData(true);
            }).catch(function (error) {
            });
        }
        
        $scope.retryAll = function() {
        	CommonAdminService.retryAllJobSendSessionAva()
            	.then(function (data) {
            		$scope.tableJobSendSessionAva.reloadInternalData(true);
            }).catch(function (error) {
            });
        }
        
        $scope.modalRetry = function(modal) {
        	if(modal && modal.annexaFormly && modal.annexaFormly.model && modal.annexaFormly.model.id){
        		$scope.retry(modal.annexaFormly.model.id);
        		modal.close();
        	}
        }
    	
        $scope.viewElement = function (id) {
            RestService.findOne('JobSendSessionAva', id)
                .then(function(data) {
                    var decodeddata = JSOG.decode(data.data);
                    var modal = angular.copy(CommonAdminModals.jobSendSessionAvaDetail);
                    modal.annexaFormly.model = { id: decodeddata.id};
                    modal.annexaFormly.model.row1 =  { errorMessage: decodeddata.errorMessage, 
                    		status: decodeddata.status,
                    		sessionId: decodeddata.session.id,
                    		sessionNumber: decodeddata.session.sessionNumber,
                    		lastUpdate: $filter('date')(new Date(decodeddata.updateStateDate), 'dd/MM/yyyy - HH:mm'),
                    		attempts: decodeddata.attempts};
                    modal.annexaFormly.options = {};
                    modal.annexaFormly.options.formState = {readOnly: false};
                    AnnexaFormlyFactory.showModal('modalJobSendSessionAvaSee', modal, $scope.modalRetry, false, undefined, $filter('translate')('global.literals.retry'));
                });
        }
        
    }])